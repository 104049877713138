import React, { useEffect } from 'react'
import { useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { Dropdown } from 'reactjs-dropdown-component';
import axiosInstance from '../helperFuncs/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { t } from "i18next";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import '../assets/style/calendar.css'
import warningIcon from "../assets/images/warning-icon.png"
import axiosInstance_multi from '../helperFuncs/axiosInstance_multi_data';


export default function CalendarPage() {

    const [start_date, setStart_date] = useState(undefined)
    const [end_date, setEnd_date] = useState(undefined)
    const [userNote, setUserNote] = useState("")

    const [calendarStartDate, setCalendarStartDate] = useState(undefined)
    const [calendarEndDate, setCalendarEndDate] = useState(undefined)

    const [canUserGetLeaveRequest, setCanUserGetLeaveRequest] = useState(true)
    const [whenCanGetRequest, setWhenCanGetRequest] = useState(undefined)
    const [userHasRightsToHaveAnnualLeave, setUserHasRightsToHaveAnnualLeave] = useState(undefined)
    const [totalGainedAnnualLeave, setTotalGainedAnnualLeave] = useState(0)
    const [usedAnnualLeaves, setUsedAnnualLeaves] = useState(0)
    const [report, setReport] = useState(undefined)
    const navigate = useNavigate()
    const [showTextarea, setShowTextarea] = useState(false);
    const [selectedReason, setSelectedReason] = useState("")
    const [leaveRequests, setLeaveRequests] = useState([])
    const [selecterReasonText, setSelectedReasonText] = useState(undefined)
    const userObject = useSelector((state) => state.userSlice)
    const [fromDate, setFromDate] = useState(new Date())



    const handleSectionClick = (e) => {
        const { className, textContent } = e.target;
        if (className === 'dd-header-title' && textContent === 'Other...') {
            setShowTextarea(true);
        } else {
            setShowTextarea(false);
        }
    };

    const [data, setData] = useState({})

    const getLeaveRequests = () => {
        axiosInstance.get('/requests/leave/' + userObject.user._id).then((res) => {
            setUsedAnnualLeaves(res?.data?.leaves[0]?.sum != undefined ? res?.data?.leaves[0]?.sum : 0)
            setCanUserGetLeaveRequest(res.data.canUserGetLeaveRequest)
            setUserHasRightsToHaveAnnualLeave(res.data.userHasRightsToHaveAnnualLeave)
            setLeaveRequests(res.data)
            setWhenCanGetRequest(res.data.whenToGetLeaveRequest)
            setTotalGainedAnnualLeave(res.data.totalGainedAnnualLeave != undefined ? res.data.totalGainedAnnualLeave : 0)
        })
    }

    const getLeaveReasonInfo = () => {
        axiosInstance.get('/leavereasons').then((result) => {
            setData(result.data)
        }).catch((error) => {
            alert.error(<div style={{ color: "white" }}>error</div>)
        })
    }

    useEffect(() => {
        getLeaveReasonInfo()
        getLeaveRequests()
    }, [])


    const dateToDaysDifference = (start_date, endDate) => {
        let start = new Date(Date.parse(start_date)).getTime()
        let end = new Date(Date.parse(endDate)).getTime()
        let days_difference = Math.ceil((end - start) / (1000 * 3600 * 24))
        return days_difference
    }

    const sendLeaveRequest = () => {
        let date_start = start_date.year + "-" + (Number(start_date.month) + 1) + "-" + start_date.day
        let date_end = end_date.year + "-" + (Number(end_date.month) + 1) + "-" + end_date.day
        let formData = new FormData()
        formData.append("start_date", date_start)
        formData.append("end_date", date_end)
        formData.append("message", userNote)
        formData.append("type", selectedReason)
        formData.append("total_day", dateToDaysDifference(date_start, date_end))
        formData.append("file", report)




        axiosInstance_multi.post('/requests/leave/add', formData).then((result) => {
            navigate("/calendar")
        })
            .catch((error) => {
                alert('An error occured !')
            })
    }

    const formatDateForInput = (date) => {
        let parsedDate = new Date(Date.parse(date))
        let tolocalstring = parsedDate.toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }).split('/')
        let formattedDate = parsedDate.getFullYear() + "-" + tolocalstring[1] + "-" + parsedDate.getDate()
        return formattedDate
    }

    const updateLeaveRequest = (item_id, obj) => {
        axiosInstance.patch('/requests/leave/edit/' + item_id, obj).then((result) => {
            document.querySelector(`[data-update-btn="${item_id}"]`).textContent = "Updated"
            setTimeout(() => {
                document.querySelector(`[data-update-btn="${item_id}"]`).textContent = "Update"
            }, 3000)


        })
    }

    const deleteLeaveRequest = (item_id) => {
        axiosInstance.delete('/requests/leave/delete/' + item_id).then((result) => {
            document.querySelector(`[data-delete-btn="${item_id}"]`).textContent = "Deleted"
            setTimeout(() => {
                document.querySelector(`[data-leave_id="${item_id}"]`).remove()
            }, 1000)


        })
    }

    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }


    const calculateTimeDifference = (start_date_cal, end_date_cal) => {
        let startDateSplit = document.querySelector('#date-start').placeholder.split('/')
        let endDateSplit = document.querySelector('#date-end').placeholder.split('/')

        const startDateFromDoc = new Date(startDateSplit[2] + "/" + startDateSplit[1] + "/" + startDateSplit[0])
        const endDateFromDoc = new Date(endDateSplit[2] + "/" + endDateSplit[1] + "/" + endDateSplit[0])


        let difference = endDateFromDoc.getTime() - startDateFromDoc.getTime();
        let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
        return TotalDays
    }


    return (

        <div className='app'>
            <h3 style={{ marginTop: "80px", marginBottom: "100px", marginLeft: "280px", fontWeight: 'bold' }} >{t("Request a Leave")}:</h3>
            {!canUserGetLeaveRequest ?

                <div className='request-page-warning-section'>
                    <div className="request-warning-icon">
                        <img src={warningIcon} alt="Request page warning icon" className='warning-icon' />
                    </div>
                    <div className='request-page-warning-text'>
                        <p className='request-page-warning-title'>{t("WARNING")}</p>
                        <p className='request-page-warning-subtext'>{t("You must have been working for at least 3 months to request a leave")}</p>
                        <div className='request-page-warning-subtext'>{t("You have to wait until")}<span style={{ color: "#6666ff", fontWeight: "bold" }}>{whenCanGetRequest}</span></div>
                    </div>
                </div>
                :
                <div className='request-main-content'>


                    <div style={{ marginBottom: "15px", opacity: userHasRightsToHaveAnnualLeave == undefined ? 0.5 : 1 }}>
                        {userHasRightsToHaveAnnualLeave == undefined &&
                            <h1 style={{ textAlign: "center" }}>You have not completed your first anniversary to see calculation</h1>
                        }
                        <p style={{ textAlign: "center", fontWeight: "bold" }}>{(totalGainedAnnualLeave - usedAnnualLeaves)} {t("Days left out of")} {totalGainedAnnualLeave} {t("days")}</p>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <ProgressBar
                                className='profile-page-wrapper '
                                maxCompleted={totalGainedAnnualLeave}
                                completed={usedAnnualLeaves ? usedAnnualLeaves : 0}
                                bgColor="#FC4F5A"
                                height="30px"
                                isLabelVisible={false}
                                labelColor="#e80909"
                            />
                        </div>
                        <p style={{ fontWeight: "bold", textAlign: "center", marginTop: "15px", }}>{usedAnnualLeaves ? usedAnnualLeaves : 0}  {t("Days used")}</p>
                    </div>


                    {leaveRequests?.data?.length > 0 &&
                        <>
                            <h4 style={{ marginBottom: 25, marginTop: 80, marginLeft: 280, fontWeight: "bold" }}>{t("Previous Requests")}:</h4>
                            <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>

                                <div style={{ height: 250, width: "100%", overflowY: "scroll" }}>
                                    {leaveRequests?.data?.filter((item) => item?.approved == null)?.map((item) => {
                                        return (
                                            <div data-leave_id={item._id} style={{ padding: 5, display: "flex", justifyContent: "space-around", border: "1px solid rgb(93, 95, 239)", borderRadius: "14px", width: "55%", margin: "0 auto", marginBottom: "5px" }}>
                                                <span style={{ margin: "auto 0" }}>
                                                    <p>{t("Message")}: {item?.message}</p>
                                                    <div style={{ marginBottom: "15px" }}>
                                                        <label>{t("Start Date")}: </label>
                                                        <input style={{ marginLeft: "15px", padding: "5px", border: "1px solid #e9ecef", borderRadius: "14px" }} onChange={(e) => {
                                                            document.querySelector('#start_date_' + item._id).value = e.target.value
                                                        }} id={'start_date_' + item._id} type={"date"} defaultValue={formatDateForInput(item.start_date)} />
                                                    </div>
                                                    <div>  <label>{t("End Date")}: </label>
                                                        <input style={{ marginLeft: "22px", padding: "5px", border: "1px solid #e9ecef", borderRadius: "14px" }} id={'end_date_' + item._id} type={"date"} defaultValue={formatDateForInput(item.end_date)} />
                                                    </div>
                                                </span>
                                                <div style={{ margin: "95px 0" }}>
                                                    <button data-update-btn={item._id} onClick={() => {
                                                        let getStartDate = document.querySelector('#start_date_' + item._id).value
                                                        let getEndDate = document.querySelector('#end_date_' + item._id).value
                                                        let obj = { "start_date": getStartDate, "end_date": getEndDate }
                                                        updateLeaveRequest(item._id, obj)

                                                    }} style={{ border: "none", background: "#6666ff", color: "white !important", padding: "5px 12px", borderRadius: "4px", marginRight: 5 }}>
                                                        <span style={{ color: "white" }}>{t("Update")}</span>
                                                    </button>

                                                    <button data-delete-btn={item._id} onClick={() => {
                                                        deleteLeaveRequest(item._id)
                                                    }} style={{ border: "none", background: "rgb(252, 79, 90)", color: "white !important", padding: "5px 12px", borderRadius: "4px" }}><span style={{ color: "white" }}>{t("Delete")}</span></button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </>
                    }
                    <div sty class="container_in_content"
                        style={{ width: "100%", "height": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "center", position: "relative" }}>
                        <div id="calendar" style={{ "position": "relative", width: "100%", display: "flex", justifyContent: "center" }}>

                            <DayPicker
                                fromDate={fromDate}
                                styles={{ width: "100%", height: "100%" }}
                                mode="range"
                                selected={{ from: calendarStartDate, to: calendarEndDate }}

                                onDayClick={(e) => {
                                    if (calendarStartDate == undefined) {
                                        setCalendarStartDate(e)
                                        setFromDate(e)
                                        setStart_date({ day: e.getDate(), month: e.getMonth(), year: e.getFullYear() })
                                    }
                                    if (calendarStartDate != undefined && calendarEndDate == undefined) {
                                        setFromDate(new Date())
                                        if (e.getTime() < calendarStartDate.getTime()) {
                                            alert('Pick greater time !')
                                            setCalendarStartDate(undefined)
                                            setStart_date(undefined)
                                            return
                                        }
                                        setCalendarEndDate(e)
                                        setEnd_date({ day: e.getDate(), month: e.getMonth(), year: e.getFullYear() })
                                    }
                                    if (calendarStartDate != undefined && calendarEndDate != undefined) {
                                        setCalendarStartDate(e)
                                        setFromDate(e)
                                        setStart_date({ day: e.getDate(), month: e.getMonth(), year: e.getFullYear() })
                                        setEnd_date(undefined)
                                        setCalendarEndDate(undefined)
                                    }

                                }


                                }
                            />
                        </div>
                    </div>

                    <section className='request-page'>
                        <div style={{ display: "flex", gap: "45px", fontSize: "12px" }}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="">{t("Start Date")}</label>
                                <input disabled type="text" name="" id="date-start" className='request-date-range' placeholder={start_date ? start_date.day + "/" + (Number(start_date.month) + 1) + "/" + start_date.year : ""} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="">{t("End Date")}</label>
                                <input disabled type="text" name="" id="date-end" className='request-date-range' placeholder={end_date ? end_date.day + "/" + (Number(end_date.month) + 1) + "/" + end_date.year : ""} />
                            </div>
                        </div>
                        <div className='request-dropdown-menu' style={{ maxWidth: 360 }}>
                            <h2 style={{ fontSize: "18px", marginTop: "60px", marginBottom: "40px" }}>{t("Reason of the leave")}:</h2>
                            <div className="dropdown-menu-section" onClick={handleSectionClick} style={{ flexDirection: selectedReason === "other" ? "column" : "row" }} >
                                <Dropdown
                                    name="location"
                                    title={t("Select")}
                                    list={data.data?.filter((item) => {
                                        let label = item.reason

                                        const user_start_date = userObject?.user?.employment_start_date
                                        const parsedDate = new Date(Date.parse(user_start_date))
                                        const addedDays = parsedDate.addDays(365)
                                        const today = new Date()
                                        if (label == "Annual Leave") {
                                            if (today.getTime() > addedDays.getTime()) {

                                                return true
                                            }
                                            else {
                                                return false
                                            }
                                        }
                                        else {
                                            return true

                                        }
                                    }).map((item) => {
                                        let label = item.reason
                                        let obj = { "label": label, "value": item._id }
                                        return obj
                                    }) || []}
                                    onChange={(e) => { setSelectedReasonText(e.label); setSelectedReason(e.value) }}
                                    styles={{
                                        headerTitle: { fontSize: "16px", fontWeight: 400 },
                                        listItem: { fontSize: "16px", fontWeight: 400 },
                                    }}
                                />
                                <input onChange={(e) => { setUserNote(e.target.value) }} style={{ borderRadius: 8, padding: 8, border: "1px solid #5d5fef" }} type={"text"} placeholder={t("Your note")} />
                                {selecterReasonText == "Sick" &&
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <input onChange={(e) => { setReport(e.target.files[0]) }} type={"file"} />
                                    </div>
                                }
                                <div>
                                    {selectedReason === "other" && (
                                        <textarea name="postContent" rows={6} cols={60} style={{ marginTop: 55 }} />
                                    )}
                                    {selectedReason === "sickleave" && (
                                        <button className="report-btn">{t("Add Report")}</button>
                                    )}
                                </div>
                            </div>
                        </div>
                        {selectedReason ?
                            <input onClick={() => {
                                let allowedDays = totalGainedAnnualLeave - usedAnnualLeaves

                                let dayDifferenceBetweenRequest = calculateTimeDifference()
                                if (dayDifferenceBetweenRequest > allowedDays && selecterReasonText == "Annual Leave") {
                                    alert('You are able to request ' + allowedDays + " days but you requested " + dayDifferenceBetweenRequest + " days")
                                    return
                                }
                                else {
                                    sendLeaveRequest()
                                }


                            }} className="request-submitBtn" type="button" value={t("Submit")} />
                            : <h4 style={{ textAlign: "left", padding: "15px 0px", marginBottom: 100 }}>Please select leave type !</h4>
                        }
                    </section>
                </div>
            }
        </div>
    );
}

