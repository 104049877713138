import React from 'react';
import "./Request.css";
import {t} from "i18next";

function Anniversary({ anniversary }) {
  const formatDate = (date) => {
    const parseDate = new Date(Date.parse(date));
    const formattedDay = parseDate.getDate();
    const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(parseDate);
    return { formattedDay, formattedMonth, parseDate };
  };
  return (
    <>
      <div className="row py-2" style={{justifyContent:"center"}}>
        <div className="col-5 reqblock" style={{width:"95%", display:"flex", alignItems:"center"}}>
          <div style={{marginLeft:100}}>
            {anniversary && (
                <>
                 <h1 style={{ fontSize: 85 }}>
                    {formatDate(anniversary.employment_start_date).formattedDay}
                </h1>
                <h1 style={{fontSize:30}}>
                    {formatDate(anniversary.employment_start_date).formattedMonth}
                </h1>
                </>
            
            )}
          </div>
          <div className="row h-40 main">
            {anniversary && (
              <>
                <div className='notification-anniversary-title'>
                  <p>{anniversary.name} {anniversary.surname} {t("Anniversary")}</p>
                </div>
                <div className='notification-event-detail'>
                  <p>{t("Anniversary Detail")}: <span style={{fontWeight:"bold", color:"#FF3300"}}>{anniversary.anniversaryMissingDays} {t("day left")}</span></p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Anniversary;

