import React, { useState } from 'react';
import axiosInstance from '../../helperFuncs/axiosInstance';
import "./Request2.css";
import { t } from "i18next";
import { useSelector } from 'react-redux';
import {Buffer} from 'buffer';


function RequestTwo({ leave, hostname = "", userNotification = false }) {
    const [checkAccepted, setCheckAccepted] = useState(null)
    const acceptOrRejectLeaveRequest = (leave_id, status) => {
        axiosInstance.post('/requests/leave/status/' + leave_id, { status: status }).then((res) => {
            setCheckAccepted(res.data.approved)
        })
    }

    const userObject = useSelector((state) => state.userSlice)

    const stringToDate = (date) => {
        return new Date(Date.parse(date)).getDate() + "/" + new Date(Date.parse(date)).getMonth() + "/" + new Date(Date.parse(date)).getFullYear()
    }


    const arrayToImageUrl = (profile_photo=undefined)=>{
        if(profile_photo !=undefined){
            let {contentType,data} =profile_photo 
            let bufferedData = (Buffer.from(data.data,"base64"))
            let imageurl = URL.createObjectURL(new Blob([bufferedData.buffer],{type:contentType}))
            return imageurl
        }
        return ""
    }

    const nameAndSurname = ()=>{
        if(userNotification){
            return userObject?.user?.name + " "+ userObject?.user?.surname
        }
        else{
            return leave?.user_id?.name + " " + leave?.user_id?.surname 
        }
    }


    return (
        <>
            <div className="row py-2" style={{ justifyContent: "center" }}>
                <div className="col-12 reqblock2" style={{boxShadow:"none"}}>
                    <div className="row h-100">
                        <div className="col-2">
                            <img width={90} height={90} src={userNotification ? arrayToImageUrl(userObject.user.profile_photo) : arrayToImageUrl(leave.user_id.profile_photo) } alt="" style={{ backgroundColor: "#D9D9D9", borderRadius: "50%" }} className="mt-5" />
                        </div>
                        <div className="col-5">
                            <div className="row h-100 coltwo" style={{ alignItems: "center", }}>
                                <div className="col-12 ">
                                    <h5 className='pt-3' style={{ fontSize: "18px" }}>{t("Name")}: {nameAndSurname()}</h5>
                                </div>
                                <div className="col-12">
                                    <h5 style={{ fontSize: "18px" }}>{t("Duration")}:
                                        {stringToDate(leave?.start_date)} -  {stringToDate(leave?.end_date)}

                                    </h5>
                                </div>
                                <div className="col-12">

                                    <h5 style={{ fontSize: "18px" }}>{t("Reason")}: {leave?.type?.reason}</h5>
                                </div>
                                {leave.file !=undefined &&
                                <a target={"_blank"} href={hostname + leave.file} >Report</a>
                            }
                            </div>
                        </div>

                        <div className="col-5">
                            <div className="row h-100">
                                <div className="col-12">
                                    <h5 className='pt-5' style={{ fontSize: "18px" }}>{t("Total Day")}: {leave?.total_day}</h5>
                                </div>


                                {userNotification == false ?
                                    <div>
                                        {leave?.approved == null && checkAccepted == null ? (
                                            <>
                                                <div className="col-12 d-flex" style={{ justifyContent: "end", alignItems: "center" }}>
                                                    <div className='h-50 pe-5'>

                                                        <button className='btn text-white' onClick={() => { acceptOrRejectLeaveRequest(leave._id, true) }} style={{ backgroundColor: "#17B5AD", }}>{checkAccepted == true ? t("Accepted") : t("Accept")}</button>

                                                        <button className='btn text-white' onClick={() => { acceptOrRejectLeaveRequest(leave._id, false) }} style={{ backgroundColor: "#FC4F5A", marginLeft: "15px" }}>{checkAccepted == false ? t("Rejected") : t("Reject")}</button>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                            :

                                            <div className="col-12 d-flex" style={{ justifyContent: "end", alignItems: "center" }}>
                                                <div className='h-50 pe-5'>
                                                    {(leave.approved || checkAccepted) ?
                                                        <button className='btn text-white' style={{ backgroundColor: "#17B5AD", }}>{t("Accepted")}</button>
                                                        :
                                                        <button className='btn text-white' style={{ backgroundColor: "#FC4F5A", marginLeft: "15px" }}>{t("Rejected")}</button>
                                                    }
                                                </div>
                                            </div>



                                        }
                                    </div>
                                    :
                                    <div className="col-12 d-flex" style={{ justifyContent: "end", alignItems: "center" }}>
                                    <div className='h-50 pe-5'>
                                        {(leave.approved) ?
                                            <button className='btn text-white' style={{ backgroundColor: "#17B5AD", }}>{t("Accepted")}</button>
                                            :
                                            leave.approved == null 
                                            ? 

                                            <button className='btn text-white' style={{ backgroundColor: "#ACB9FF", marginLeft: "15px" }}>{t("Pending")}</button>
                                        :
                                        <button className='btn text-white' style={{ backgroundColor: "#FC4F5A", marginLeft: "15px" }}>{t("Rejected")}</button>

                                        }
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestTwo