import React, { useEffect, useState } from 'react';


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useAlert } from 'react-alert'
import Table from 'react-bootstrap/Table';
import axiosInstance from '../../helperFuncs/axiosInstance';
import {t} from "i18next";

export default function AddPosition() {
    const alert = useAlert()

    const [data, setData] = useState({});
    const [positions, setPositions] = useState([])
    const [leaveReason, setLeaveReason] = useState([])
    const [workingTypes, setWorkingTypes] = useState([])
    const [message, setMessage] = useState('');

    const [leaveReasonInputValue, setLeaveReasonInputValue] = useState('');
    const [positionsInputValue, setPositionsInputValue] = useState('');
    const [workingTypeInputValue, setWorkingTypeInputValue] = useState('');

    const [currentUpdatedPosition, setCurrentUpdatedPosition] = useState("")

    const getAdminPanelInfo = () => {

        axiosInstance.get('/positions').then((result) => {
            setPositions(result.data.data)
        }).catch((error) => {
            alert.error(<div style={{ color: 'white' }}>error</div>);
        })
    };

    useEffect(() => {
        getAdminPanelInfo();
    }, []);
    

    const handleAddPosition = (event) => {
        event.preventDefault();
        axiosInstance.post('/positions/add', { name: positionsInputValue }).then((result) => {
            setPositions((prevstate)=>{
                let position_obj = {
                    name:result.data.name,
                    _id:result.data._id
                }
                return(
                    [...prevstate,position_obj]
                )

            })
            setPositionsInputValue('');
            setMessage({ text: 'Added✔️', color: 'green' });

        }).catch((error) => {
            setMessage({ text: 'Error❌', color: 'red' });
        })

    }

    const handleInputChange = (event) => {
        setLeaveReasonInputValue(event.target.value);
        setMessage('');

    };

    const handleAddPositionChange = (event) => {
        setPositionsInputValue(event.target.value);
        setMessage('');

    };
    const updatePosition = (positionName) => {
        let position_input = document.querySelector('#position_input_' + positionName).style.display

        if (position_input == "block") {
            document.querySelector('#position_input_' + positionName).style.display = "none"
            document.querySelector('#position_label_' + positionName).style.display = "block"
            document.querySelector('#table-approvedBtn_' + positionName).style.display = "none"
        }
        else {
            document.querySelector('#position_input_' + positionName).style.display = "block"
            document.querySelector('#position_label_' + positionName).style.display = "none"
            document.querySelector('#table-approvedBtn_' + positionName).style.display = "block"
        }
    };

    const handlePositionChange = (e) => {
        setCurrentUpdatedPosition(e.target.value)

    }


    const handleUpdatePosition = (id,positionName) => {
        // const newName = document.getElementById(`position_input_${id}`).value;

        axiosInstance
            .patch(`/positions/${id}`, { name: currentUpdatedPosition })
            .then((result) => {
                document.querySelector('#position_input_' + positionName).style.display = "none"
                document.querySelector('#position_label_' + positionName).style.display = "block"
                document.querySelector('#table-approvedBtn_' + positionName).style.display = "none"
                setPositions((prevstate)=>{
                    let willBeUpdated =  prevstate.find((position)=>position._id == id)
                    willBeUpdated.name = result.data.name
                    return(
                     [...prevstate]
                    )
                 })
                setMessage({ text: 'Updated✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };

    const handleDeletePosition = (id) => {
        // const newName = document.getElementById(`position_input_${id}`).value;
        axiosInstance
            .delete(`/positions/${id}`, { name: currentUpdatedPosition })
            .then((result) => {
                let updatedItemIndex = positions.findIndex((item)=>item._id == id)
                var copied_positions = [...positions]
                copied_positions.splice(updatedItemIndex,1)
                setPositions(copied_positions)
                setMessage({ text: 'Deleted✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "35px", marginTop: 5, marginLeft: 15 }}>
                <div style={{ marginBottom: 50 }}>

                    <p>
                       {t("New Position")}
                    </p>

                    <form onSubmit={handleAddPosition} style={{ display: "flex", alignItems: "center", marginLeft: "10%" }}>
                        <Form.Control type="text" placeholder= {t("Position")} value={positionsInputValue} onChange={handleAddPositionChange} className='input-bg' />
                        <Button type="submit" variant="primary" className='submit-form-btn'>{t("Add Position")}</Button>
                        {message && <p style={{ color: message.color, display: "flex" }}>{message.text}</p>}
                    </form>

                </div>
            </div>
            <h3 style={{ marginBottom: 20, marginLeft: 50 }}>{t("Positions")}</h3>
            <Table responsive="md" style={{ maxWidth: "50%", marginBottom: 60, marginLeft: 50 }}>

                <tbody>
                    {positions && positions.map((position, index) => {
                        let id_for_detech = position._id
                        return (
                            <tr key={index} style={{ borderTop: "1px solid #dee2e6" }}>
                                <td style={{ fontWeight: 500, display: "flex", gap: 5 }}>
                                    <input
                                        style={{ display: "none" }}
                                        id={"position_input_" + id_for_detech}
                                        type="text"
                                        onChange={(e) => handlePositionChange(e)}
                                        placeholder={position.name}
                                    />

                                    <p id={"position_label_" + id_for_detech}>{position.name}</p>
                                    <div style={{ display: "flex", gap: 2 }}>
                                        <button
                                            id={"table-approvedBtn_" + id_for_detech}
                                            style={{ display: "none", border: "none", background: "none" }}
                                            onClick={() => handleUpdatePosition(position._id,id_for_detech)}
                                        >
                                             ✔️
                                        </button>
                                    </div>
                                </td>
                                <td style={{ fontWeight: "bold" }}><button  className='table-deleteBtn' onClick={() => handleDeletePosition(position._id)}>{t("Delete")}</button></td>
                                <td style={{ fontWeight: "bold" }}><button onClick={() => { updatePosition(id_for_detech) }} className='table-updateBtn'>{t("Update")}</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

        </div>
    );
}