import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useAlert } from 'react-alert'
import Table from 'react-bootstrap/Table';
import axiosInstance from '../../helperFuncs/axiosInstance';
import {t} from "i18next";

export default function AddLeaveReason() {
    const alert = useAlert()

    const [leaveReason, setLeaveReason] = useState([])

    const [message, setMessage] = useState('');

    const [leaveReasonInputValue, setLeaveReasonInputValue] = useState('');

    const [currentUpdatedReason, setCurrentUpdatedReason] = useState("")

    const getAdminPanelInfo = () => {

        axiosInstance.get('/leavereasons').then((result) => {
            setLeaveReason(result.data)
        }).catch((error) => {
            alert.error(<div style={{ color: 'white' }}>error</div>);
        })

    };

    useEffect(() => {
        getAdminPanelInfo();
    }, []);



    const handleAddLeaveReason = (event) => {
        event.preventDefault();
        axiosInstance.post('/leavereasons/add', { reason: leaveReasonInputValue }).then((result) => {
            setLeaveReason((prevstate)=>{
                let new_array = prevstate

                const getIndexOfObjext = new_array.data.find((leavereason)=>leavereason._id == result.data._id)

                let leavereason_obj = {
                    reason:result.data.reason,
                    _id:result.data._id
                }
                if(getIndexOfObjext == undefined){
                    new_array.data.push(leavereason_obj)

                }


                return(
                    new_array
                )

            })
            setLeaveReasonInputValue('');
            setMessage({ text: 'Added✔️', color: 'green' });

        }).catch((error) => {
            setMessage({ text: 'Error❌', color: 'red' });
        })
 
    };


    const handleAddLeaveReasonChange = (event) => {
        setLeaveReasonInputValue(event.target.value);
        setMessage('');

    };


    const updateLeaveReason = (reasonName) => {
        let leavereason_input = document.querySelector('#leavereason_input_' + reasonName).style.display

        if (leavereason_input == "block") {
            document.querySelector('#leavereason_input_' + reasonName).style.display = "none"
            document.querySelector('#leavereason_label_' + reasonName).style.display = "block"
            document.querySelector('#table-approvedBtn_' + reasonName).style.display = "none"
        }
        else {
            document.querySelector('#leavereason_input_' + reasonName).style.display = "block"
            document.querySelector('#leavereason_label_' + reasonName).style.display = "none"
            document.querySelector('#table-approvedBtn_' + reasonName).style.display = "block"
        }
    };


    const handleLeaveReasonChange = (e) => {
        setCurrentUpdatedReason(e.target.value)

    }


    const handleUpdateLeaveReason = (id, reasonName) => {

        axiosInstance
            .patch(`/leavereasons/${id}`, { reason: currentUpdatedReason })
            .then((result) => {
                document.querySelector('#leavereason_input_' + reasonName).style.display = "none"
                document.querySelector('#leavereason_label_' + reasonName).style.display = "block"
                document.querySelector('#table-approvedBtn_' + reasonName).style.display = "none"
                setLeaveReason((prevstate) => {
                    let copiedState = prevstate

                    let willBeUpdated = copiedState.data.find((leaveReason) => leaveReason._id == id)
                    willBeUpdated.reason = result.data.reason
                    return (
                      copiedState
                    )
                })
                setMessage({ text: 'Updated✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };

    const handleDeleteLeaveReason = (id) => {
        axiosInstance
            .delete(`/leavereasons/${id}`, { reason: currentUpdatedReason })
            .then((result) => {


                let updatedItemIndex = leaveReason.data.findIndex((item) => item._id == id)
                var copied_leavereasons = leaveReason
                copied_leavereasons.data.splice(updatedItemIndex, 1)
                setLeaveReason(copied_leavereasons)

                setMessage({ text: 'Deleted✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };

    return (
        <div>
            <div>

            </div>

            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "35px", marginTop: 5, marginLeft: 15 }}>
                <div style={{ marginBottom: 50 }}>
                    <p>
                        {t("New Leave Reason")}
                    </p>

                    <form onSubmit={handleAddLeaveReason} style={{ display: "flex", alignItems: "center", marginLeft: "9%" }}>
                        <Form.Control type="text" value={leaveReasonInputValue} onChange={handleAddLeaveReasonChange} placeholder={t("Leave Reason")} className='input-bg' />
                        <Button type="submit" variant="primary" className='submit-form-btn'>{t("Add Leave Reason")}</Button>
                        {message && <p style={{ color: message.color }}>{message.text}</p>}
                    </form>

                </div>
            </div>

            <h3 style={{ marginBottom: 20, marginLeft: 50 }}>{t("Leave Reason Types")}</h3>
            <Table responsive="md" style={{ maxWidth: "50%", marginBottom: 60, marginLeft: 50 }}>
                <tbody>
                    {leaveReason && leaveReason.data?.map((leavereasonck, index) => {
                        let leavereason = leavereasonck
                        if(leavereason.reason == "Annual Leave"){
                            leavereason.reason = "Annual Leave (Needed for calculation)"
                        }

                        let id_for_detech = leavereason._id
                        return (
                            <tr key={index} style={{ borderTop: "1px solid #dee2e6" }}>

                                <td style={{ fontWeight: 500, display: "flex", gap: 5 }}>
                                    <input
                                        style={{ display: "none" }}
                                        id={"leavereason_input_" + id_for_detech}
                                        type="text"
                                        onChange={(e) => handleLeaveReasonChange(e)}
                                        placeholder={leavereason.reason}
                                    />
                                    <p id={"leavereason_label_" + id_for_detech}>{leavereason.reason}</p>
                                    <div style={{ display: "flex", gap: 2 }}>
                                        <button
                                            id={"table-approvedBtn_" + id_for_detech}
                                            style={{ display: "none", border: "none", background: "none" }}
                                            onClick={() => handleUpdateLeaveReason(leavereason._id, id_for_detech)}
                                        >
                                            ✔️
                                        </button>
                                    </div>
                                </td>
                                <td style={{ fontWeight: "bold" }}><button className='table-deleteBtn' onClick={() => handleDeleteLeaveReason(leavereason._id)}>{t("Delete")}</button></td>
                                <td style={{ fontWeight: "bold" }}><button className='table-updateBtn' onClick={() => { updateLeaveReason(id_for_detech) }}>{t("Update")}</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>

        </div>
    );
}