import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom"
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import  store  from "./redux_related/store"
import { Provider } from 'react-redux'
import { saveState } from './redux_related/localstorage';
import throttle from 'lodash.throttle';

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

store.subscribe(throttle(() => {
  saveState(store.getState());
}, 1000));


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <App />
        </Provider>
      </AlertProvider>
    </BrowserRouter>,
    

);
 
