import React from 'react'
import Request from '../components/notificationcomp/Request';
import {t} from "i18next";

export default function EmployeesPage() {
    return (
    <div>
        <div className='row px-4'>
            <div className="col-12">
                <h2 style={{fontSize:"26px",fontWeight:"700",paddingTop:"90px",  marginLeft:10, marginBottom:30}}>{t("Employees")}</h2>
            </div>
            <div className="col-12 d-flex" style={{flexDirection:"column", margin:"0 auto 50px"}}>
                <Request></Request>
                <Request></Request>
                <Request></Request>
            </div>       
        </div>
    </div>
    )
}

 