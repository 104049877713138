import logo from "../assets/images/logo.png"
import { FaEdit } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import ProgressBar from "@ramonak/react-progress-bar";
import axiosInstance from "../helperFuncs/axiosInstance";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert'
import { t } from "i18next";
import { useSelector } from "react-redux";
import { Buffer } from 'buffer';
import Table from 'react-bootstrap/Table';



export default function ProfilePage() {
    const alert = useAlert()

    const [data, setData] = useState({})
    const [leaveRequests, setLeaveRequests] = useState([])
    const userObject = useSelector((state) => state.userSlice)
    const [totalGainedAnnualLeave, setTotalGainedAnnualLeave] = useState(0)
    const [usedAnnualLeaves, setUsedAnnualLeaves] = useState(0)
    const [totalWorkingDays, setTotalWorkingDays] = useState(0)





    const getUserInfo = () => {
        axiosInstance.get('/user/detail').then((result) => {
            setData(result.data)
            setTotalWorkingDays(result.data.totalWorkingDays)

        }).catch((error) => {
            alert.error(<div style={{ color: "white" }}>error</div>)
        })
    }
    const getLeaveRequests = () => {
        axiosInstance.get('/requests/leave/' + userObject.user._id).then((res) => {
            setLeaveRequests(res.data.data)
            console.log(res.data.leaves[0]?.sum,'resdataaarequestleave')
            setUsedAnnualLeaves(res?.data?.leaves[0]?.sum)
            setTotalGainedAnnualLeave(res.data.totalGainedAnnualLeave)


        })
    }

    useEffect(() => {
        getLeaveRequests()
        getUserInfo()
    }, [])

    const arrayToImageUrl = (profile_photo = undefined) => {
        if (profile_photo != undefined) {
            let { contentType, data } = profile_photo
            let bufferedData = (Buffer.from(data.data, "base64"))
            let imageurl = URL.createObjectURL(new Blob([bufferedData.buffer], { type: contentType }))
            return imageurl
        }
        return ""
    }


    return (
        <div>
            <div style={{ width: "100%", textAlign: "right" }}>
                <img src={logo} alt="audteye-logo" className="profile-page-logo" />
            </div>
            <div className="update-info">
                <div><FaEdit /></div>
              
                <a href='/user/update' style={{ textDecoration: "none", color: "black", fontSize: "18px" }}>{t("Update Info")}</a>
            </div>
            <div className="profile-page-info">
                <div className="profile-picture" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <img style={{ maxHeight: "100%", maxWidth: "100%" }} src={arrayToImageUrl(data?.user?.profile_photo)} />
                </div>
                <h1 className="profile-title">{t("Name")}:</h1>
                <p className="profile-text">{data.user?.name}</p>
                <h1 className="profile-title">{t("Position")}:</h1>
                {data.user?.positions.map((item,index) => {
                    return (
                        <p key={index} className="profile-text">
                            {item.name}
                        </p>
                    )
                })}


                <h1 className="profile-title">{t("Type")}:</h1>
                <p className="profile-text">{data.user?.working_type?.name}</p>
                <div style={{ display: "flex", gap: "150px", alignItems: "center", marginTop: 0 }}>
                    <div>
                        <h1 className="profile-title" >{t("Employment start date")}:</h1>
                        {data.user?.employment_start_date && (
                            <p className="profile-text">{data.user.employment_start_date.slice(0, 10)}</p>
                        )}
                    </div>

                    <div >
                        <h1 className="profile-title">{t("Contract end date")}:</h1>
                        {data.user?.employment_end_date ? (
                            <p className="profile-text">{data.user.employment_end_date.slice(0, 10)}</p>
                        ) : (
                            <p className="profile-text">-</p>
                        )}

                    </div>
                </div>
                <div style={{ marginTop: "100px", marginBottom: "50px" }}>
                    <h1 className="profile-title">{t("Leave Days")}:</h1>
                    <div style={{ marginBottom: "40px", marginTop: "40px" }}>
                        <p style={{ fontWeight: "bold", marginLeft: "20%" }}>{totalGainedAnnualLeave - usedAnnualLeaves} {t("Days left out of")} {totalGainedAnnualLeave} {t("days")}</p>
                        <ProgressBar
                            className='profile-page-wrapper '
                            maxCompleted={totalGainedAnnualLeave}
                            completed={usedAnnualLeaves ? usedAnnualLeaves : 0}
                            bgColor="#FC4F5A"
                            height="30px"
                            isLabelVisible={false}
                            labelColor="#e80909"
                        />
                        <p style={{ fontWeight: "bold", marginTop: "15px", marginLeft: "25%"}}>{usedAnnualLeaves ? usedAnnualLeaves : 0}  {t("Days used")}</p>
                    </div>
                </div>
            </div>
            <Table responsive="md" style={{ maxWidth: "50%", marginBottom: 60, marginLeft: 150 }}>
                <thead>
                    <tr>
                    </tr>
                </thead>
                <tbody>
                    {leaveRequests.map((item,index) => {
                        return (
                            <tr key={index} style={{ borderTop: "1px solid #dee2e6" }}>
                                <td style={{ fontWeight: 500 }}>{item.start_date.slice(0, 10)} -- {item.end_date.slice(0, 10)} </td>
                                {item.approved == null ?
                                    <td style={{ color: "#ACB9FF", fontWeight: "bold" }}>{t("Pending")}</td>
                                    : item.approved ? (
                                        <td style={{ color: "#00BB83", fontWeight: "bold" }}>{t("Approved")}</td>

                                    ) :
                                        (<td style={{ color: "#F00000", fontWeight: "bold" }}>{t("Rejected")}</td>)
                                }
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <h1 className="profile-title" style={{ fontSize: 18, marginBottom: 30, marginLeft: 150 }}>{t("Total Working Days")}: <span style={{ color: "rgb(0, 187, 131)" }}>{totalWorkingDays}</span> </h1>
            <div style={{ marginLeft: 150, marginTop: 50 }}>
                <h1 className="profile-title">{t("Social Media Accounts")}:</h1>
                <div className="social-media">
                    {data.user?.twitter_link && <a href={data.user?.twitter_link}><FaTwitter color={"black"}  /></a> }
                    {data.user?.linkedin_link && <a href={data.user?.linkedin_link}><FaLinkedin color={"black"}  /></a> }
                    {data.user?.discord_link && <a href={data.user?.discord_link}><FaDiscord color={"black"}  /></a> }

                </div>
            </div>
        </div>
    )
}

