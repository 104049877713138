import React, { useEffect, useState } from 'react';


import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useAlert } from 'react-alert'
import Table from 'react-bootstrap/Table';
import axiosInstance from '../../helperFuncs/axiosInstance';
import {t} from "i18next";

export default function AddWorkingType() {
    const alert = useAlert()

    const [workingTypes, setWorkingTypes] = useState([])
    const [message, setMessage] = useState('');
    const [workingTypeInputValue, setWorkingTypeInputValue] = useState('');
    const [currentUpdatedWorkingType, setCurrentUpdatedWorkingType] = useState("")


    const getAdminPanelInfo = () => {

        axiosInstance.get('/workingtypes').then((result) => {
            setWorkingTypes(result.data)
        }).catch((error) => {
            alert.error(<div style={{ color: 'white' }}>error</div>);
        })

    };

    useEffect(() => {
        getAdminPanelInfo();
    }, []);

    const handleAddWorkingType = () => {
        axiosInstance.post('/workingtypes/add', { name: workingTypeInputValue }).then((result) => {
            setWorkingTypes((prevstate)=>{
                let new_array = prevstate

                const getIndexOfObjext = new_array.data.find((workingtype)=>workingtype._id == result.data._id)
            
                    let working_type_obj = {
                        name:result.data.name,
                        _id:result.data._id
                    }
                    if(getIndexOfObjext == undefined){
                        new_array.data.push(working_type_obj)

                    }

                return(
                    new_array
                )

            })
            setWorkingTypeInputValue('');
            setMessage({ text: 'Added✔️', color: 'green' });

        }).catch((error) => {
            setMessage({ text: 'Error❌', color: 'red' });
        })
 
    };


    const handleUpdateWorkingtype = (id, workingTypeName) => {
        // const newName = document.getElementById(`position_input_${id}`).value;

        axiosInstance
            .patch(`/workingtypes/${id}`, { name: currentUpdatedWorkingType })
            .then((result) => {
                document.querySelector('#workingtype_input_' + workingTypeName).style.display = "none"
                document.querySelector('#workingtype_label_' + workingTypeName).style.display = "block"
                document.querySelector('#table-approvedBtn_' + workingTypeName).style.display = "none"
                
                setWorkingTypes((prevstate) => {
                    
                    let copiedState = prevstate
                    let willBeUpdated = copiedState.data.find((workingtype) => workingtype._id == id)
                    willBeUpdated.name = result.data.name
                    return (
                        copiedState
                    )
                })
                setMessage({ text: 'Updated✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };


    const updateWorkingType = (workingtype) => {
        let working_type_input = document.querySelector('#workingtype_input_' + workingtype).style.display

        if (working_type_input == "block") {
            document.querySelector('#workingtype_input_' + workingtype).style.display = "none"
            document.querySelector('#workingtype_label_' + workingtype).style.display = "block"
            document.querySelector('#table-approvedBtn_' + workingtype).style.display = "none"
        }
        else {
            document.querySelector('#workingtype_input_' + workingtype).style.display = "block"
            document.querySelector('#workingtype_label_' + workingtype).style.display = "none"
            document.querySelector('#table-approvedBtn_' + workingtype).style.display = "block"
        }
    };

    const handleWorkingType = (e) => {
        setWorkingTypeInputValue(e.target.value)
        setMessage('');


    }

    const handleCurrentWorkingType = (e) => {
        setCurrentUpdatedWorkingType(e.target.value)
        setMessage('');


    }

    const handleDeleteWorkingType = (id) => {
        axiosInstance
            .delete(`/workingtypes/${id}`, { reason: currentUpdatedWorkingType })
            .then((result) => {
                let updatedItemIndex = workingTypes.data.findIndex((item) => item._id == id)
                var copied_workingtyps = workingTypes

                copied_workingtyps.data.splice(updatedItemIndex, 1)
                setWorkingTypes(copied_workingtyps)

                setMessage({ text: 'Deleted✔️', color: 'green' });
            })
            .catch((error) => {
                setMessage({ text: 'Error❌', color: 'red' });
            });
    };


    return (
        <div>
            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "35px", marginTop: 5, marginLeft: 15 }}>
                <div style={{ marginBottom: 50 }}>
                    <p>
                        {t("New Working Type")}
                    </p>
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "9%" }}>
                        <Form.Control  type="text" placeholder={t("Working Type")} value={workingTypeInputValue} onChange={handleWorkingType} className='input-bg' />
                        <Button onClick={()=>{handleAddWorkingType()}} type="button" variant="primary" className='submit-form-btn'>{t("Add Working Type")}</Button>
                        {message && <p style={{ color: message.color, display: "flex" }}>{message.text}</p>}
                    </div>

                </div>
            </div>
            <h3 style={{ marginBottom: 20, marginLeft: 50 }}>{t("Working Types")}</h3>
            <Table responsive="md" style={{ maxWidth: "50%", marginBottom: 60, marginLeft: 50 }}>
                <tbody>
                    {workingTypes && workingTypes.data?.map((workingtype, index) => {
                        let id_for_detech = workingtype._id
                        return (
                            <tr key={index} style={{ borderTop: "1px solid #dee2e6" }}>

                                <td style={{ fontWeight: 500, display: "flex", gap: 5 }}>
                                    <input
                                        style={{ display: "none" }}
                                        id={"workingtype_input_" + id_for_detech}
                                        type="text"
                                        onChange={(e) => handleCurrentWorkingType(e)}

                                        placeholder={workingtype.name}

                                    />
                                    <p id={"workingtype_label_" + id_for_detech}>{workingtype.name}</p>
                                    <div style={{ display: "flex", gap: 2 }}>
                                        <button
                                            id={"table-approvedBtn_" + id_for_detech}
                                            style={{ display: "none", border: "none", background: "none" }}
                                            onClick={() => handleUpdateWorkingtype(workingtype._id, id_for_detech)}
                                        >
                                            ✔️
                                        </button>
                                    </div>
                                </td>
                                <td style={{ fontWeight: "bold" }}><button  className='table-deleteBtn' onClick={() => handleDeleteWorkingType(workingtype._id)}>{t("Delete")}</button></td>
                                <td style={{ fontWeight: "bold" }}><button onClick={() => { updateWorkingType(id_for_detech) }} className='table-updateBtn'>{t("Update")}</button></td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    );
}