export let en = {
    "Notifications": "Notifications",
    "Admin Profile":"Admin Profile",
    "Admin Panel":"Admin Panel",
    "Admin Leave Requests":"Admin Leave Requests",
    "Calendar":"Calendar",
    "Profile":"Profile",
    "Request a leave":"Request a leave",
    "Name":"Name",
    "Position":"Position",
    "Type":"Type",
    "Employment start date":"Employment start date",
    "Contract end date":"Contract end date",
    "Leave Days":"Leave Days",
    "Social Media Accounts":"Social Media Accounts",
    "4 Days left out of 14":"4 Days left out of 14",
    "10 Days used":"10 Days used",
    "Employees":"Employees",
    "Add information to be shown in the report of each user":"Add information to be shown in the report of each user",
    "New employee profile":"New employee profile",
    "Add new employee":"Add new employee",
    "Log Out":"Log Out",
    "Current employees":"Current employees",
    "Show Profile":"Show Profile",
    "Edit":"Edit",
    "Delete":"Delete",
    "Add Position":"Add Position",
    "Add position type to your employees":"Add position type to your employees",
    "Add Working Type":"Add Working Type",
    "Add working type to your employees":"Add working type to your employees",
    "Add Leave Reason":"Add Leave Reason",
    "Add leave reason to be shown in the report of each user":"Add leave reason to be shown in the report of each user",
    "Add Events":"Add Events",
    "Add company events and share them with your employees":"Add company events and share them with your employees",
    "Performance Report":"Performance Report",
    "Add information to be shown in the report of each other":"Add information to be shown in the report of each other",
    "New Position":"New Position",
    "Positions":"Positions",
    "Update":"Update",
    "Select":"Select",
    "New Working Type":"New Working Type",
    "Working Type":"Working Type",
    "Working Types":"Working Types",
    "New Leave Reason":"New Leave Reason",
    "Leave Reason Types":"Leave Reason Types",
    "Leave Reason":"Leave Reason",
    "Event Title":"Event Title",
    "Add here":"Add here",
    "Event Description":"Event Description",
    "Text here":"Text here",
    "Start Date":"Start Date",
    "End Date":"End Date",
    "Add PDF":"Add PDF",
    "Upload":"Upload",
    "Publish Event":"Publish Event",
    "Employee Name":"Employee Name",
    "Send a Note":"Send a Note",
    "Month":"Month",
    "Year":"Year",
    "Add a Performance Badge":"Add a Performance Badge",
    "Add a Report":"Add a Report",
    "Leave Requests":"Leave Requests",
    "Duration":"Duration",
    "Reason":"Reason",
    "Total Day":"Total Day",
    "Accepted":"Accepted",
    "Accept":"Accept",
    "Rejected":"Rejected",
    "Reject":"Reject",
    "Date requested":"Date requested",
    "All":"All",
    "Approved":"Approved",
    "Pending":"Pending",
    "Google Events":"Google Events",
    "Birthdays":"Birthdays",
    "Leave":"Leave",
    "Common Events":"Common Events",
    "Sun":"Sun",
    "Mon":"Mon",
    "Tue":"Tue",
    "Wed":"Wed",
    "Thu":"Thu",
    "Fri":"Fri",
    "Sat":"Sat",
    "Upcoming Events":"Upcoming Events",
    "Upcoming Birthdays":"Upcoming Birthdays",
    "Upcoming Users' Anniversaries":"Upcoming Users' Anniversaries",
    "Birthday":"Birthday",
    "Anniversary":"Anniversary",
    "Anniversary Detail":"Anniversary Detail",
    "day left":"day left",
    "Update Info":"Update Info",
    "General":"General",
    "Surname":"Surname",
    "Phone":"Phone",
    "Day":"Day",
    "Working type":"Working type",
    "User Types":"User Types",
    "Id number":"Id number",
    "Marital Status":"Marital Status",
    "Married":"Married",
    "Single":"Single",
    "Gender":"Gender",
    "Male":"Male",
    "Female":"Female",
    "Education Status":"Education Status",
    "High School":"High School",
    "University":"University",
    "Disability":"Disability",
    "Nationality":"Nationality",
    "Blood type":"Blood type",
    "Number of Children":"Number of Children",
    "Address Info":"Address Info",
    "Address Full":"Address Full",
    "Home Phone":"Home Phone",
    "Country":"Country",
    "City":"City",
    "Postal Code":"Postal Code",
    "Bank":"Bank",
    "Account Owner":"Account Owner",
    "Account number":"Account number",
    "Personal Info":"Personal Info",
    "Other Info":"Other Info",
    "Bank Info":"Bank Info",
    "Request a Leave":"Request a Leave",
    "Previous Requests":"Previous Requests",
    "Message":"Message",
    "Reason of the leave":"Reason of the leave",
    "Your note":"Your note",
    "Add Report":"Add Report",
    "Submit":"Submit",
    "Days used":"Days used",
    "Employee":"Employee",
    "Start working day":"Start working day",
    "Total Working Days":"Total Working Days",
    "Days left out of":"Days left out of",
    "days":"days",
    "Photo":"Photo",
    "Sick":"Sick",
    "Vacation":"Vacation",
    "Leave reason":"Leave reason",
    "Coming Age":"Coming Age",
    "WARNING":"WARNING",
    "You must have been working for at least 3 months to request a leave":"You must have been working for at least 3 months to request a leave",
    "You have to wait until":"You have to wait until",
    "Download Leaves’ Report":"Download Leaves’ Report",
    "Select user":"Select user",
    "Download Leave Requests for the last":"Download Leave Requests for the last",
    "1 month’s report":"1 month’s report",
    "3 month’s report":"3 month’s report",
    "6 month’s report":"6 month’s report",
    "1 year’s report":"1 year’s report",
    "Download":"Download",
    "Download Excel":"Download Excel",
    "Prepare Excel":"Prepare Excel",
    "Passed":"Passed",
    
  }
  