import logo from "../assets/images/logo.png"
import { FaEdit } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import axiosInstance from "../helperFuncs/axiosInstance";
import { useAlert } from 'react-alert'
import { useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import {t} from "i18next";

export default function AdminProfile() {


    const currentUserType = useSelector((state) => state.currentUserType)

    useLayoutEffect(()=>{
        if(currentUserType.is_admin != true){
            window.location.href = "/profile"
        }
    },[])



    const alert = useAlert()


    const [data,setData]=useState({})

    const getAdminInfo = ()=>{
        axiosInstance.get('/user/detail').then((result)=>{
            setData(result.data)
        }).catch((error)=>{
           alert.error(<div style={{color:"white"}}>error</div>)
        })
    }

    useEffect(()=>{
        getAdminInfo()
    },[])
  

    return (
        <div>
            <div style={{width:"100%",textAlign:"right"}}>           
                <img src={logo} alt="audteye-logo" className="profile-page-logo" />
            </div>
            <div className="update-info">
                <div><FaEdit /></div>
            </div>
            <div className="profile-page-info">
                <div className="profile-picture"></div>
                <h1 className="profile-title">{t("Name")}:</h1>
                <p className="profile-text">{data.user?.name}</p>
                <h1 className="profile-title">{t("Position")}:</h1>
                {data.user?.positions.map((item,index)=>{
                    return(
                        <p key={index} className="profile-text">
                        {item.name}
                    </p>
                    )
                })}
                <div className="social-media" style={{marginLeft:0}}>  
                    <FaTwitter />
                    <FaLinkedin />
                    <FaDiscord />
                </div>
            </div>
        </div>
    )
}