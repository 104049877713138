import React from 'react';
import "./Request.css";
import { t } from "i18next";

function Birthdays({ birthday }) {

    const today = new Date()
    const parsedDate = new Date(Date.parse(birthday.birthday))

    const formatDate = (date) => {
        const parseDate = new Date(Date.parse(date));
        const formattedDay = parseDate.getDate();
        const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(parseDate);
        return { formattedDay, formattedMonth, parseDate };
    };


    const checkIfPassed = () => {
        let passed = false

        if (today.getTime() > parsedDate.getTime()) {
            passed = true
        }
        return passed
    }

    return (
        <>
            <div className="row py-2" style={{ justifyContent: "center" }}>

                <div className="col-5 reqblock" style={{ width: "95%", display: "flex", alignItems: "center" }}>
                    <div style={{ marginLeft: 100 }}>
                        <h1 style={{ fontSize: 85 }}>
                            {birthday?.birthday_date}
                        </h1>
                        <h1 style={{ fontSize: 30 }}>
                            {formatDate(`${birthday?.birthday}`).formattedMonth}
                        </h1>
                    </div>
                    <div className="row h-40 main">
                        <div className='notification-birthday-title'>
                            {checkIfPassed() &&
                                <h1 style={{ textAlign: "center", color: "#fc4f5a" }}>{t("Passed")}!</h1>
                            }
                            <p>{birthday.user_name} {birthday.user_surname} {t("Birthday")}</p>
                            <p style={{ fontSize: 16, fontWeight: 400 }}>{t("Coming Age")}: <span style={{ fontWeight: "bold", color: "rgb(23, 181, 173)" }}>{birthday.new_age} </span></p>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Birthdays