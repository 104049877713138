import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import axiosInstance from '../../helperFuncs/axiosInstance';
import axiosInstance_multi from '../../helperFuncs/axiosInstance_multi_data';
import {t} from "i18next";

export default function AddEvent() {

    const [events, setEvents] = useState([])
    const [eventsTitleInputValue, setEventsTitleInputValue] = useState('');
    const [message, setMessage] = useState('');
    const [eventsDescriptionInputValue, setEventsDescriptionInputValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [eventPdf,setEventPdf]=useState(null)

    const getAdminPanelInfo = () => {

        axiosInstance.get('/events').then((result) => {
            setEvents(result.data.data)
        }).catch((error) => {
            alert.error(<div style={{ color: 'white' }}>error</div>);
        })
    };

    useEffect(() => {
        getAdminPanelInfo();
    }, []);



    const handleAddEvent = () => {
        let formData = new FormData()
        formData.append("name",eventsTitleInputValue)
        formData.append("description",eventsDescriptionInputValue)
        formData.append("start_date",startDate)
        formData.append("end_date",endDate)
        formData.append("event_pdf",eventPdf)





        axiosInstance_multi.post('/events/add', formData).then((result) => {
            setEventsTitleInputValue('');
            setMessage({ text: 'Added✔️', color: 'green' });

        }).catch((error) => {
            setMessage({ text: 'Error❌', color: 'red' });
        })

    }


    const handleAddEventsTitleChange = (event) => {
        setEventsTitleInputValue(event.target.value);
        setMessage('');

    };

    const handleAddEventsDescriptionChange = (event) => {
        setEventsDescriptionInputValue(event.target.value);
        setMessage('');

    };



    return (
        <div>
            <div style={{ marginLeft: 15 }}>
                <p>
                    {t("Event Title")}
                </p>
                <Form.Group onSubmit={handleAddEvent} className="event-form-group" controlId="formGroupEmail" style={{ width: "18%" }}>
                    <Form.Control 
                        type="text" 
                        placeholder={t("Add here")}
                        className='input-bg' 
                        value={eventsTitleInputValue} 
                        onChange={handleAddEventsTitleChange} 
                    />
                </Form.Group>
                <p>
                    {t("Event Description")}
                </p>
                <FloatingLabel controlId="floatingTextarea2" onSubmit={handleAddEvent} >
                    <Form.Control
                        onChange={handleAddEventsDescriptionChange}
                        value={eventsDescriptionInputValue}
                        as="textarea"
                        placeholder={t("Text here")}
                        style={{ height: '100px', maxWidth: "60%", marginLeft: 35, background: "#F9F9F9" }}
                    />
                </FloatingLabel>
                <div style={{ display: "flex", alignItems: "center", gap: 35, marginTop: 15 }}>
                    <div>
                        <p>
                            {t("Start Date")}
                        </p>
                        <Form.Group className="event-form-group" controlId="formGroupEmail"  style={{ width: "100%" }}>
                            <Form.Control onKeyDown={(e)=>{e.preventDefault()}} type="date"  placeholder="07" value={startDate} onChange={(event) => {
                            
                                setStartDate(event.target.value)}} className='input-bg' />
                        </Form.Group>
                    </div>
                    <div>
                        <p>
                            {t("End Date")}
                        </p>
                        <Form.Group className="event-form-group" controlId="formGroupEmail"  style={{ width: "100%" }}>
                            <Form.Control onKeyDown={(e)=>{e.preventDefault()}}  type="date" placeholder="09" value={endDate} onChange={(event) => {setEndDate(event.target.value)}} className='input-bg' />
                        </Form.Group>
                    </div>
                </div>
                <p style={{ marginTop: 25 }}>
                    {t("Add PDF")}
                </p>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: 35 }}>
                   <input type={"file"} onChange={(e)=>{
                    setEventPdf(e.target.files[0])
                   }}/>
                </div>

                <Button onClick={()=>{handleAddEvent()}} className="form-btn" type="submit"  style={{ width: "15%", fontWeight: "bold" }}>{t("Publish Event")}</Button>
                {message && <p style={{ color: message.color }}>{message.text}</p>}
            </div>
        </div>
    )
}

