import React, { useEffect, useState, useLayoutEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import Button from 'react-bootstrap/Button';
import 'react-accessible-accordion/dist/fancy-example.css';
import axiosInstance from '../helperFuncs/axiosInstance';
import { positions, useAlert } from 'react-alert'
import AddPosition from '../components/adminpanel/AddPosition';
import AddLeaveReason from '../components/adminpanel/AddLeaveReason';
import AddWorkingType from '../components/adminpanel/AddWorkingType';
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select';
import AddEvent from '../components/adminpanel/AddEvent';
import AddPerformanceReport from '../components/adminpanel/AddPerformanceReport';
import { t } from "i18next";

export default function AdminPanel() {

    const navigate = useNavigate()
    const userObject = useSelector((state) => state.userSlice)

    const [selectedUser,setSelectedUser]=useState("")
    const [usersList,setUsersList]=useState([])

    useLayoutEffect(() => {
        if (userObject.user.is_admin != true) {
            window.location.href = "/profile"
        }
    }, [])


    /* main content */

    const listen_accordion = () => {
        Array.from(document.querySelectorAll('.accordion__button')).forEach((accordion) => {
            const panel = document.querySelectorAll('.accordion__panel');
            accordion.addEventListener('click', () => {
                if (accordion.getAttribute('aria-expanded') == "false") {
                    accordion.style.borderBottomLeftRadius = "0px";
                    accordion.style.borderBottomRightRadius = "0px";
                    accordion.parentElement.nextElementSibling.style.borderTopLeftRadius = "0px";
                    accordion.parentElement.nextElementSibling.style.borderTopRightRadius = "0px";

                } else {
                    accordion.style.borderBottomLeftRadius = "14px";
                    accordion.style.borderBottomRightRadius = "14px";
                }
            });
        });
    };

    useEffect(() => {
        listen_accordion();
    }, []);




    const getAllUsers = ()=>{
        let users_list = []

        axiosInstance.get('/user/all').then((res)=>{
            Array.from(res.data.users).forEach((user)=>{
                let obj = {
                    label: user.name + " " + user.surname,
                    value: user._id
                }
                users_list.push(obj)
            })
            setUsersList(users_list)
        })
    }

    const deleteUserByAdmin = (user_id) => {
        axiosInstance.delete('/admin/user/delete/' + user_id).then((res) => {
            setUsersList((prevState) => {
                let copiedState = prevState
                let deletedItemIndex = copiedState.findIndex((item) => { return item.value == user_id })
                copiedState.splice(deletedItemIndex, 1)
                return copiedState
            })
            document.querySelector('.css-1uccc91-singleValue').textContent = ""
        })
    }

    useEffect(() => {
        getAllUsers()
    }, [])

    return (
        <div>
            <div style={{ width: "100%", textAlign: "right" }}>
                <img src={logo} alt="audteye-logo" className="profile-page-logo" />
            </div>
            <Accordion allowZeroExpanded="true" className='admin-panel-accordion'>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Employees")}</h3>
                            <p>{t("Add information to be shown in the report of each user")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                        </div>
                        <div style={{ marginLeft: 15 }}>
                            <p>
                                {t("New employee profile")}
                            </p>
                            <Link to="/admin/employee"><button className='newEmployeeBtn'>{t("Add new employee")}</button></Link>
                        </div>
                        <div style={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "35px", marginTop: 25, marginLeft: 15 }}>
                            <div>
                                <p>
                                    {t("Current employees")}
                                </p>
                                <Select
                                    className="current-employees-select"
                                    onChange={(e) => { setSelectedUser(e.value) }}
                                    options={usersList}
                                    placeholder={t("Select")}
                                />
        
                            </div>

                            <Link to={"/reports/"+selectedUser}> <Button variant="primary" className="form-btn" style={{ fontWeight: "bold" }}>{t("Show Profile")}</Button></Link>
                            {selectedUser !="" && 
                            <a href={"/admin/employee/"+selectedUser}><Button  variant="warning" className="form-btn bg-warning" style={{ fontWeight: "bold" }}>{t("Edit")}</Button></a>
                    }
     {selectedUser !="" && 
                        <Button onClick={()=>{deleteUserByAdmin(selectedUser)}} className="form-btn delete-btn" style={{ fontWeight: "bold"}}>{t("Delete")}</Button>}

                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Add Position")}</h3>
                            <p>{t("Add position type to your employees")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <AddPosition />
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Add Working Type")}</h3>
                            <p>{t("Add working type to your employees")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div>
                        </div>
                        <AddWorkingType />
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Add Leave Reason")}</h3>
                            <p>{t("Add leave reason to be shown in the report of each user")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <AddLeaveReason />
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Add Events")}</h3>
                            <p>{t("Add company events and share them with your employees")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel >
                        
                        <AddEvent />
                    </AccordionItemPanel>
                </AccordionItem>
                {/* <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Performance Report")}</h3>
                            <p>{t("Add information to be shown in the report of each other")}</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        
                        <AddPerformanceReport />
                    </AccordionItemPanel>
                </AccordionItem> */}
            </Accordion>
        </div>
    );
}