import React from 'react';
import "./BankForm.css";
import UpdateNavbar from '../UpdateNavbar/UpdateNavbar';
import { useEffect, useState } from "react";
import axiosInstance from '../../helperFuncs/axiosInstance';
import {t} from "i18next";

function BankForm({setUserObject,bank_info, is_admin,userObject}) {

    const [data,setData]=useState({})

    const getBankInfo = ()=>{
        axiosInstance.get('/bank/accounts').then((result)=>{
            setData(result.data)
        }).catch((error)=>{
            alert.error(<div style={{color:"white"}}>error</div>)
        })
    }

    useEffect(()=>{
        getBankInfo()
    },[])

    const handleUserObjectData = (e)=>{
        let key_object = e.target.name
        let value = e.target.value
  
        setUserObject((prevState)=>{
            let copiedState = prevState
            copiedState['bank_info'][key_object]=value
            return(copiedState)
        
        })
    }


  return (
    <>
     <div className='GeneralForm'>
        <div className='form-1'>
            <div>
                <label htmlFor="bank">{t("Bank")}:</label>
                <input disabled={!is_admin} style={{background:"#E4E8FE"}} onChange={handleUserObjectData} placeholder={userObject.bank_info?.bank} type="text" name="bank" id="bank" />
            </div>
            <div className='item-2'>
                <label htmlFor="">Iban:</label>
                <input onChange={handleUserObjectData} placeholder={userObject.bank_info?.iban}  type="text" name="iban" id="iban" style={{width:"95%",background:"#E4E8FE"}} />
            </div>
            <div className='item-3'>
                <label htmlFor="">{t("Account Owner")}:</label>
                <input  onChange={handleUserObjectData} placeholder={userObject.bank_info?.account_owner}  type="text" name="account_owner" id="account_owner" style={{width:"95%",background:"#E4E8FE"}}/>        
            </div>
            <div className='item-4'>
                <label htmlFor="">{t("Account number")}:</label>
                <input style={{background:"#E4E8FE"}} onChange={handleUserObjectData} placeholder={userObject.bank_info?.account_number}  type="text" name="account_number" id="account_number" />        
            </div>
        </div>
    </div>
    </>
   
  )
}

export default BankForm