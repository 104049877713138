import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import Events from '../components/notificationcomp/Events';
import Birthdays from '../components/notificationcomp/Birthdays';
import Anniversary from '../components/notificationcomp/Anniversary';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import axiosInstance from '../helperFuncs/axiosInstance';
import RequestTwo from '../components/notificationcomp/RequestTwo';
import {t} from "i18next";
import { useSelector } from 'react-redux';

export default function UserNotifications() {

    const [leaves, setLeaves] = useState([])
    const [maxPage, setMaxPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const [upcomingEvents, setUpcomingEvents] = useState([])
    const [upcomingBirthdays, setUpcomingBirthdays] = useState([])
    const [userAnniversary, setUserAnniversary] = useState([])
    const [hostname,setHostname]=useState("")
        const [totalRecordEvent,setTotalRecordEvent]=useState(0)
    const [showRedDotEvents,setShowRedDotEvents]=useState(false)




    const listen_accordion = () => {
        Array.from(document.querySelectorAll('.accordion__button')).forEach((accordion) => {
            const panel = document.querySelectorAll('.accordion__panel');
            accordion.addEventListener('click', () => {
                if (accordion.getAttribute('aria-expanded') == "false") {

                    accordion.style.borderBottomLeftRadius = "0px";
                    accordion.style.borderBottomRightRadius = "0px";
                    accordion.parentElement.nextElementSibling.style.borderTopLeftRadius = "0px";
                    accordion.parentElement.nextElementSibling.style.borderTopRightRadius = "0px";

                } else {
                    accordion.style.borderBottomLeftRadius = "14px";
                    accordion.style.borderBottomRightRadius = "14px";
                }
            });
        });
    };

    useEffect(() => {
        listen_accordion();

    }, []);

    const getAllLeaveRequests = () => {
        axiosInstance.get('/requests/leave?page=' + currentPage).then((res) => {
            setMaxPage(res.data.total_page)
            setLeaves(res.data.data)
            setHostname(res.data.hostname)
        })
    }

    const getUpcomingEvents = () => {
        let today_copy = new Date()
        let add30daystoDay = new Date(today_copy.setDate(today_copy.getDate()+30))
        axiosInstance.get('/admin/commonevents/upcoming').then((events) => {
            let filteredData = events.data.data.filter((item)=>{
                let parsedData = new Date(Date.parse(item.start_date))
                let difference = (parsedData.getTime() - add30daystoDay.getTime())
                let howManyDays = Math.ceil(difference / (1000 * 3600 * 24));
                return howManyDays<30
            })
            setUpcomingEvents(filteredData)
        })
    }

    const getUpcomingBirthdays = () => {
        axiosInstance.get('/admin/birthdays/upcoming').then((birthdays) => {
            setUpcomingBirthdays(birthdays.data)
            
        })
    }

    const getUserAnniversary = () => {
        axiosInstance.get('/admin/anniversary/upcoming').then((birthdays) => {
            setUserAnniversary(birthdays.data)
        })
    }

    useEffect(() => {
        getAllLeaveRequests()

    }, [currentPage])

    useEffect(() => {
        getUpcomingEvents()
        getUpcomingBirthdays()
        getUserAnniversary()
        getNotificationCountForEvents()
    }, [])


          const getNotificationCountForEvents = ()=>{
        axiosInstance.get('/events/notifications/events/count').then((res)=>{
            setTotalRecordEvent(res.data.eventsCount)
    
          let notificationCount = window.localStorage.getItem('notificationCountforEventsUser')
          if (notificationCount === null) {
            window.localStorage.setItem('notificationCountforEventsUser', 0)
          }
          if (window.localStorage.getItem('notificationCountforEventsUser') != res.data.eventsCount) {
    
            setShowRedDotEvents(true)
          }
    
        })
      }

    const userObject = useSelector((state) => state.userSlice)
    const today = new Date()


    return (
        <div>
            <h2 style={{ margin: "70px 70px" }}>{t("Notifications")}</h2>
            <Accordion allowZeroExpanded="true" className='admin-panel-accordion' style={{ marginTop: "60px" }}>
                <AccordionItem>

                    <AccordionItemHeading>
                        <AccordionItemButton>

                            <h3>{t("Leave Requests")} <span style={{marginLeft:5,background:"purple",padding:"1px 4px",borderRadius:"50%",color:"white"}}>{leaves?.length}</span></h3>

                        </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                        <div className="col-12 d-flex" style={{ flexDirection: "column" }}>
                        {leaves.length < 1 ?
                                <h5 style={{ textAlign: "center" }}>There is no leave request.</h5>
                                :

                                leaves.map((item, index) => {
                                    return (
                                        <RequestTwo key={index} userNotification={true} hostname={hostname} leave={item}></RequestTwo>

                                    )
                                })
                            }
               
                        </div>
                        <PaginationControl
                            page={currentPage}
                            between={4}
                            total={maxPage}
                            limit={1}
                            changePage={(page) => {
                                setCurrentPage(page)
                            }}
                            ellipsis={1}
                        />
                    </AccordionItemPanel>

                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>

                                          <h3 onClick={()=>{   
                                window.localStorage.setItem('notificationCountforEventsUser', totalRecordEvent)
                                setShowRedDotEvents(false)}}
                                 style={{position:"relative"}}>
                                <span>{t("Upcoming Events")}<span style={{marginLeft:5,background:"purple",padding:"1px 4px",borderRadius:"50%",color:"white"}}>{upcomingEvents?.length}</span></span>
                                {showRedDotEvents && 
                            <div style={{padding:5,background:"red",position:"absolute",borderRadius:"50%",top:-5,left:145}}></div>
                        }

                            </h3>

                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {upcomingEvents?.map((event,index) => {
                            return <Events key={index} event={event} />

                        })}





                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Your birthday")}</h3>
                            <AccordionItemPanel style={{ boxShadow: "none" }}>

                            </AccordionItemPanel>

                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        {upcomingBirthdays?.data?.filter((item)=>{

                            let parsedBirthday = new Date(Date.parse(item.birthday))
                            
                            return (
                            
                           ( item?.user_id == userObject?.user?._id) 
                            
                            
                            )
                        
                        
                        })?.map((birthday,index) => {
                            return <Birthdays key={index} birthday={birthday} />

                        })}




                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <h3>{t("Your Anniversary")}</h3>


                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>

                        {userAnniversary?.data?.filter((item)=>item?._id == userObject?.user?._id )?.map((user_anniversary,index) => {
                            if (user_anniversary.anniversaryMissingDays != null) {
                                return <Anniversary key={index} anniversary={user_anniversary} />;
                            }
                        })}


                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
    )
}



