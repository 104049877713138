import axios from "axios";

const axiosInstance_multi = axios.create({
	// Configuration
	baseURL: process.env.REACT_APP_BACKEND_URL,
	timeout: 8000,
	headers: {
		Accept: 'application/json',
		Authorization: 'Token '+ window.localStorage.getItem('token'),
		"Content-Type": "multipart/form-data",
		  
	},
});

export default axiosInstance_multi