import React from 'react';
import "./OtherForm.css";
import UpdateNavbar from '../UpdateNavbar/UpdateNavbar';
import Form from 'react-bootstrap/Form';
import { useEffect, useState } from "react";
import axiosInstance from '../../helperFuncs/axiosInstance';
import { useSelector } from 'react-redux';
import {t} from "i18next";

function OtherForm({setUserObject,other_info, is_admin,userObject_state}) {

    const [form, setForm] = useState({});
    const [data,setData]=useState({})

    const userObject = useSelector((state) => state.userSlice)


    const getOtherFormInfo = ()=>{
        axiosInstance.get('/user/address').then((result)=>{
            setData(result.data)
            // setIsAdmin(result.data.is_admin || false);
        }).catch((error)=>{
            alert.error(<div style={{color:"white"}}>error</div>)
        })
    }

    useEffect(()=>{
        getOtherFormInfo()
    },[])



    const handleUserObjectData = (e)=>{
      let key_object = e.target.name
      let value = e.target.value

      setUserObject((prevState)=>{
          let copiedState = prevState
          copiedState['other_info'][key_object]=value
          return(copiedState)
      
      })
  }

  return (
    <>
    <Form className='form'>
      <Form.Group className="mb-3 adress-info" controlId="formBasicEmail">
        <Form.Label>{t("Address Info")}:</Form.Label>
        <Form.Control disabled={!is_admin} style={{background:"#E4E8FE"}} placeholder={userObject_state?.other_info?.address_info} onChange={handleUserObjectData} name="address_info" className="form-control-input" type="text" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("Address Full")}:</Form.Label>
        <Form.Control placeholder={userObject_state.other_info?.address_full}  onChange={handleUserObjectData} name="address_full" className="form-control-input" type="text" />
      </Form.Group>

    
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("Home Phone")}:</Form.Label>
        <Form.Control placeholder={userObject_state.other_info?.home_phone}  onChange={handleUserObjectData} name="home_phone"  className="form-control-input" type="number"  />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("Country")}:</Form.Label>
        <Form.Control placeholder={userObject_state.other_info?.country}  onChange={handleUserObjectData} name="country"  className="form-control-input" type="text" />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("City")}:</Form.Label>
        <Form.Control placeholder={userObject_state.other_info?.city} onChange={handleUserObjectData} name="city" className="form-control-input" type="text"  />
      </Form.Group>

      <Form.Group className="margin" controlId="formBasicPassword">
        <Form.Label>{t("Postal Code")}:</Form.Label>
        <Form.Control placeholder={userObject_state.other_info?.postal_code} onChange={handleUserObjectData} name="postal_code"  className="form-control-input" type="number" />
      </Form.Group>

    </Form>
      
    
    </>
    
  )
}

export default OtherForm