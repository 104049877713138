import React from "react";
import { FaRegUser } from "react-icons/fa";
import { FaRegCalendar } from "react-icons/fa";
import { FaLeaf } from "react-icons/fa";

export const AdminSidebarData = [

  {
    title: ("Admin Panel"),
    icon: <FaLeaf />,
    link: "/adminpanel",
  },
  {
    title: ("Admin Leave Requests"),
    icon: <FaLeaf />,
    link: "/leaverquests",
  },
  {
    title: ("Calendar"),
    icon: <FaRegCalendar />,
    link: "/calendar",
  },
];

export const SidebarData = [
  {
    title: ("Profile"),
    icon: <FaRegUser />,
    link: "/profile",
  },
  {
    title: ("Calendar"),
    icon: <FaRegCalendar />,
    link: "/calendar",
  },
  {
    title: ("Request a leave"),
    icon: <FaLeaf />,
    link: "/request",
  },
];
