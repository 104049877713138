import React, { useState, useEffect } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction"
import Multiselect from 'multiselect-react-dropdown';
import axiosInstance from '../helperFuncs/axiosInstance';
import { useSelector } from 'react-redux';
import {t} from "i18next";

function CalendarPage() {
  const {language} = useSelector((state) => state.languageSlice)
  const [userList,setUserList]=useState([])
  const [events, setEvents] = useState([]);
  const [selectedUser, setSelectedUser] = useState("")
  const [showGoogle, setShowGoogle] = useState(true)
  const [showLeaves, setShowLeaves] = useState(true)
  const [showCommon, setShowCommon] = useState(true)
  const [showBirthdays, setShowBirthdays] = useState(true)
  const [filterConditions,setFilterConditions]=useState({google:false,birthday:false,leave:false,custom:false})
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userObject = useSelector((state) => state.userSlice)

  const getEventsInfo = () => {
    let concattedArrays = []

    axiosInstance.get('/requests/all/leave').then((resLeave) => {
      const allLeaveRequests = resLeave.data.data
      allLeaveRequests.forEach((item) => {
        item["leave"] = true
        item["color"]="#FC4F5A"
        item["field"] = "leave"
        concattedArrays.push(item)  
      })
    })

    // axiosInstance.get('/user/birthdays').then((birthdays)=>{
    //   birthdays.data.data.forEach((birthday)=>{
    //     birthday["birthday"] = true
    //     birthday["color"]="#FBBC04"
    //     birthday["field"] = "birthday"
    //     concattedArrays.push(birthday)
    //   })
    // })

    axiosInstance.get('/admin/birthdays/upcoming').then((birthdays)=>{

      birthdays.data.data.forEach((birthday,index)=>{

      let obj = {
        id: index,
        user_id: birthdays.user_id,
        title: birthday?.user_name + " " + birthday?.user_surname ,
        color:"#FBBC04",
        field:"birthday",
        start: birthday.birthday,
        end: birthday.birthday,
        allDay: true
      }
      concattedArrays.push(obj)

      })

    })

    axiosInstance.get('/events').then((result) => {
      const googleEvents = result.data.googleEvents
      const customEvents = result.data.customEvents
      googleEvents.forEach((item) => {
        item["google"] = true
        item["color"]="#57cc99"
        item["field"] = "google"
        concattedArrays.push(item)
      })
      customEvents.forEach((item) => {
        item["custom"] = true
        item["color"]="#8080ff"
        item["field"] = "custom"
        concattedArrays.push(item)
      })
      setEvents(concattedArrays)
    }).catch((error) => {
      alert.error(<div style={{ color: 'white' }}>error</div>);
    })
  };

  useEffect(() => {
    getAllUsers()
    getEventsInfo();
  }, []);

  const stringToColour = function(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 4) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xDD;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }
  
  let users_list = []

  const getAllUsers = () => {
    axiosInstance.get('/user/all/withme').then((res) => {
      Array.from(res.data.users).forEach((user) => {
        let obj = {
          label: user.name + " " + user.surname,
          value: user._id,
          color:stringToColour(user.name + " " + user.surname)
        }
        users_list.push(obj)
      })
      setUserList(users_list)
    })
  }

  const options = userList.map((user, index) => ({
    value: user.value,
    label: user.label
  }));

  function handleChange(selected) {
    setSelectedOptions(selected);
  }

  const eventContent = (eventInfo) => {
   let user_id = (eventInfo.event._def.extendedProps.user_id)
    const defaultHtml = eventInfo.event.title + '<div class="fc-event-time">' + eventInfo.timeText + '</div>';
    const updatedHtml = defaultHtml.replace(`<div class="fc-event-time">', '<div class="fc-event-time " style="display:none;">`);
    let examplehtml = `${eventInfo.event.title}<div data-user_id=${user_id}></div>`

    return { html: examplehtml };
  }

  let filteredKeys = Object.keys(filterConditions)

  const filterItems = ()=>{
    let filteredItems = []
    for(let i =0;i <filteredKeys.length;i++){
      events.forEach((event)=>{
        if(event.field == filteredKeys[i]){
          filteredItems.push(event)
        }
      })
    }   
      return filteredItems
  }
  
  const handleSelectBox = (e) => {
    let inputName = e.target.name

    if(inputName == "google"){
      setShowGoogle(!showGoogle)
      if(showGoogle){
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          delete copiedstate[e.target.name]
          return copiedstate
        })
      }
      else{
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          copiedstate[e.target.name]=false
          return copiedstate
        })
      }
    }

    else if(inputName == "leave"){
      setShowLeaves(!showLeaves)
      if(showLeaves){
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          delete copiedstate[e.target.name]
          return copiedstate
        })
      }
      else{
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          copiedstate[e.target.name]=false
          return copiedstate
        })
      }
    }
    else if(inputName == "custom"){
      setShowCommon(!showCommon)
      if(showCommon){
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          delete copiedstate[e.target.name]
          return copiedstate
        })
      }
      else{
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          copiedstate[e.target.name]=false
          return copiedstate
        })
      }
    }
    else if(inputName == "birthday"){
      setShowBirthdays(!showBirthdays)
      if(showBirthdays){
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          delete copiedstate[e.target.name]
          return copiedstate
        })
      }
      else{
        setFilterConditions((prevstate)=>{
          let copiedstate = prevstate
          copiedstate[e.target.name]=false
          return copiedstate
        })
      }
    }
  }

  const filterByUsers = (value,checked)=>{
    let relatedDatas = Array.from(document.querySelectorAll(`[data-user_id="${value}"]`))
    relatedDatas.forEach((item)=>{
      if(checked){
        item.parentElement.parentElement.parentElement.style.display="block"
      }
      else{
        item.parentElement.parentElement.parentElement.style.display="none"
      }
    })
  }

  return (
    <div style={{ maxHeight: "1500px", display: "flex", justifyContent: "space-between", gap: "15px", marginRight: "30px" }}>

      <div style={{ flex: 1, marginLeft: "20px" }}>
        <div style={{ marginTop: "350px" }}>
          <div style={{ marginBottom: "10px", fontSize: "14px", fontWeight: "500", accentColor:"#6666FF" }}>
            <div style={{ marginBottom: "5px", accentColor:"#6666FF" }}>
              <input
                onChange={handleSelectBox}
                type="checkbox"
                id="topping"
                name="google"
                checked={showGoogle}
                value={showGoogle}
              />
              {t("Google Events")}
            </div>
            <div style={{ marginBottom: "5px" }}>
              <input
                onChange={handleSelectBox}
                type="checkbox"
                id="topping"
                name="birthday"
                checked={showBirthdays}
                value={showBirthdays}
              />
               {t("Birthdays")}
            </div>
            <div style={{ marginBottom: "5px" }}>
              <input
                onChange={handleSelectBox}
                type="checkbox"
                id="topping"
                name="leave"
                checked={showLeaves}
                value={showLeaves}
              />
              {t("Leave")}
            </div>
            <div>
              <input
                onChange={handleSelectBox}
                type="checkbox"
                id="topping"
                name="custom"
                checked={showCommon}
                value={showCommon}
              />
              {t("Common Events")}
            </div>
          </div>
        </div>
        <div>
          <Multiselect
            options={userObject.user.is_admin ? options : options.filter((user)=>user.value == userObject.user._id)  } 
            selectedValues={userObject.user.is_admin ?  userList : userList.filter((user)=>user.value == userObject.user._id) }
            onChange={handleChange}
            showCheckbox={true}
            onSelect={(selectedList,selectedItem)=>{filterByUsers(selectedItem.value,true)}}
            onRemove={(selectedList,selectedItem)=>{filterByUsers(selectedItem.value,false)}}
            style={{ chips: { background: "#6666ff", color:"white !important" } }}
            placeholder={t("Select")}
            displayValue="label"
          />
        </div>
      </div>
      <div id="calendar" style={{ flex: 2 }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prevYear,prev,next,nextYear today',
            center: 'title',
            right: ``,
          }}
          events={userObject.user.is_admin  ? filterItems() : filterItems().filter((item)=>{
            if(item.field == "leave"){
              return item.user_id == userObject.user._id
            }
            else{
              return true
            }
          })}
          eventContent={eventContent}
          locale={language}
        />
      </div>
    </div>
  )
}

export default CalendarPage