import {t} from "i18next";

export default function TabBarForForms({currentSection,handleClick}){
    return(
        <div style={{ display: 'flex', justifyContent: 'space-between', gap:80 }}>
        <button className={currentSection === 1 ? 'active' : ''} onClick={() => handleClick(1)}>
          {t("General")}
        </button>
        {/* <button className={currentSection === 2 ? 'active' : ''} onClick={() => handleClick(2)}>
          Position
        </button> */}
        <button className={currentSection === 3 ? 'active' : ''} onClick={() => handleClick(3)}>
          {t("Personal Info")}

        </button>
        <button className={currentSection === 4 ? 'active' : ''} onClick={() => handleClick(4)}>
          {t("Other Info")}
        </button>
        <button className={currentSection === 5 ? 'active' : ''} onClick={() => handleClick(5)}>
          {t("Bank Info")}
        </button>
        <button className={currentSection === 6 ? 'active' : ''} onClick={() => handleClick(6)}>
          {t("Social Media Accounts")}
        </button>
      </div>
    )
}