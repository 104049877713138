import React from 'react';
import "./Request.css";
function Events({event}) {

    const formatDate=(date)=>{
        const parseDate = new Date(Date.parse(date))
        let formattedDate = parseDate.getDate() + "-"+parseDate.getMonth()+"-"+parseDate.getFullYear()
        return {"formattedDate":formattedDate,"parseDate":parseDate}
    }
  return (
    <>
        <div className="row py-2" style={{justifyContent:"center"}}>
            <div className="col-5 reqblock" style={{width:"95%", display:"flex", alignItems:"center"}}>
                <div style={{marginLeft:100}}>
                    <h1 style={{fontSize:20}}>{formatDate(event.start_date).parseDate.getDate()+"/"+formatDate(event.start_date).parseDate.getMonth()+"/"+formatDate(event.start_date).parseDate.getFullYear()} - {formatDate(event.end_date).parseDate.getDate()+"/"+formatDate(event.end_date).parseDate.getMonth()+"/"+formatDate(event.end_date).parseDate.getFullYear()} </h1>
                </div>
                <div className="row h-50 main">
                    <div className='notification-event-date'>
                       <p>● {new Date(event.start_date).getHours().toString().padStart(2, '0') + ":" + new Date(event.start_date).getMinutes().toString().padStart(2, '0')} - {new Date(event.end_date).getHours().toString().padStart(2, '0') + ":" + new Date(event.end_date).getMinutes().toString().padStart(2, '0')}</p> 
                    </div>
                    <div className='notification-event-title'>
                        <p>{event.name}</p>
                    </div>
                    <div className='notification-event-detail'>
                        <p>{event.description}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Events