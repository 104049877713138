import React from 'react';
import "./PersonelForm.css";
import UpdateNavbar from '../UpdateNavbar/UpdateNavbar';
import { useEffect, useState } from "react";
import axiosInstance from '../../helperFuncs/axiosInstance';
import { useSelector } from 'react-redux';
import { t } from "i18next";

function PersonelForm({ setUserObject, personel_info, is_admin, userObject }) {

    const currentUserType = useSelector((state) => state.currentUserType)
    const [birthdayType, setBirthdayType] = useState("text")
    const [fullBloodType, setFullBloodType] = useState({ group: "", type: "" })


    const handleUserObjectData = (e) => {
        let key_object = e.target.name
        let value = e.target.value

        setUserObject((prevState) => {
            let copiedState = prevState
            copiedState['personel_info'][key_object] = value
            return (copiedState)

        })
    }

    const checkBloodType = (value, selectType) => {
        if (selectType == "group") {
            setFullBloodType((prevState) => {
                let copiedState = prevState
                copiedState.group = value
                return copiedState
            })
        }
        else if (selectType == "type") {
            setFullBloodType((prevState) => {
                let copiedState = prevState
                copiedState.type = value
                return copiedState
            })
        }


        let combinedBlood = fullBloodType.group + " " + fullBloodType.type
        let fakeObjectForBlood = { target: { name: "blood_type", value: combinedBlood } }
        handleUserObjectData(fakeObjectForBlood)

    }

    const parseBlood = (blood = undefined) => {
        let checkIfBloodExist = blood
        let obj = { group: "", type: "" }
        if (checkIfBloodExist != undefined) {
            let splittedBlood = checkIfBloodExist.split(' ')
            obj.group = splittedBlood[0]
            obj.type = splittedBlood[1]

        }
        return obj
    }


    useEffect(() => {
        setFullBloodType((prevState) => {
            let copiedState = prevState
            copiedState.group = parseBlood(userObject?.personel_info?.blood_type).group
            copiedState.type = parseBlood(userObject?.personel_info?.blood_type).type
            return copiedState
        })
    }, [])


    return (
        <>
            <div className='GeneralForm'>
                <div className='form-2'>
                    <div className='item1-1'>
                        <label htmlFor="">{t("Birthday")}:</label>
                        <input disabled={!is_admin} type={birthdayType} onFocus={() => { setBirthdayType('date') }} onChange={handleUserObjectData} placeholder={userObject.personel_info?.birthday?.slice(0, 10)} name="birthday" id="birthday" />
                    </div>
                    <div className='item1-2'>
                        <label htmlFor="">{t("Id number")}:</label>
                        <input
                            style={{ background: "#E4E8FE" }}
                            disabled={!is_admin}
                            onChange={handleUserObjectData}
                            placeholder={userObject.personel_info?.id_number}
                            type="number"
                            name="id_number"
                            id="id_number"
                            maxLength={11}
                            pattern="[0-9]*"
                        />
                    </div>
                    <div className='item1-3'>
                        <label htmlFor="">{t("Marital Status")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name='marital_status' style={{ width: "90%", height: "41px", border: "1px solid #6666FF", background: "#E4E8FE" }}>
                            <option hidden>{userObject.personel_info?.marital_status}</option>
                            <option value={"Married"}>{t("Married")}</option>
                            <option value={"Single"}>{t("Single")}</option>
                        </select>
                    </div>
                    <div className='item1-4'>
                        <label htmlFor="">{t("Gender")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name='gender' style={{ width: "90%", height: "41px", border: "1px solid #6666FF", background: "#E4E8FE" }}>
                            <option hidden>{userObject.personel_info?.gender}</option>
                            <option value={"Male"}>{t("Male")}</option>
                            <option value={"Female"}>{t("Female")}</option>
                        </select>
                    </div>
                    <div className='item1-5'>
                        <label htmlFor="">{t("Education Status")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name='education_status' style={{ width: "90%", height: "41px", border: "1px solid #6666FF", background: "#E4E8FE" }}>
                            <option hidden>{userObject.personel_info?.education_status}</option>
                            <option value={"High School"}>{t("High School")}</option>
                            <option value={"University"}>{t("University")}</option>
                        </select>

                    </div>
                    <div className='item1-6'>
                        <label htmlFor="">{t("Disability")}:</label>
                        <input style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.personel_info?.disability} type="text" name="disability" id="disability" />
                    </div>
                    <div className='item1-7'>
                        <label htmlFor="">{t("Nationality")}:</label>
                        <input style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.personel_info?.nationality} type="text" name="nationality" id="nationality" />
                    </div>
                    <div className='item1-8'>
                        <div htmlFor="">{t("Blood Group")}:</div>
                        <select style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={(e) => { checkBloodType(e.target.value, "group") }}>
                            <option hidden>{parseBlood(userObject?.personel_info?.blood_type).group}</option>

                            <option>A</option>
                            <option>B</option>
                            <option>AB</option>
                            <option>0</option>
                        </select>
                        {/* <input  placeholder={userObject.personel_info?.blood_type} type="hidden" name="blood_type" id="blood_type" /> */}
                    </div>
                    <div className='item1-8'>
                        <div htmlFor="">{t("Blood Type")}:</div>
                        <select style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={(e) => { checkBloodType(e.target.value, "type") }}>
                            <option hidden>{parseBlood(userObject?.personel_info?.blood_type).type}</option>

                            <option>+</option>
                            <option>-</option>
                        </select>
                    </div>

                    <div className='item1-9'>
                        <label htmlFor="">{t("Number of Children")}:</label>
                        <input style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.personel_info?.number_of_children} type="number" name="number_of_children" id="number_of_children" />
                    </div>

                </div>
            </div>
        </>

    )
}

export default PersonelForm