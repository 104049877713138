import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BankForm from '../components/forms/BankForm';
import GeneralForm from '../components/forms/GeneralForm';
import OtherForm from '../components/forms/OtherForm';
import PersonelForm from '../components/forms/PersonelForm';
import PositionForm from '../components/forms/PositionForm';
import SocialForm from '../components/forms/SocialForm';
import TabBarForForms from '../components/forms/TabNavForForms';
import axiosInstance from '../helperFuncs/axiosInstance';
import axiosInstance_multi from '../helperFuncs/axiosInstance_multi_data';
import { t } from "i18next";
import { useSelector } from 'react-redux';

function EmployeeAddByAdmin() {
  const [currentSection, setCurrentSection] = useState(1);
  const [positions, setPositions] = useState([])
  const [usertypes, setUserTypes] = useState([])
  const [employmentStartDate, setEmploymentStartDate] = useState({})
  const [employmentEndDate, setEmploymentEndDate] = useState({})
  const userObject_slice = useSelector((state) => state.userSlice)

  useLayoutEffect(() => {
    if (userObject_slice.user.is_admin != true) {
        window.location.href = "/profile"
    }
}, [])


  const [userObject, setUserObject] = useState({ user_object: {}, personel_info: {}, other_info: {}, bank_info: {} })
  const navigate = useNavigate()

  const handleClick = (section) => {
    setCurrentSection(section);
  };

  const getPositions = () => {
    axiosInstance.get('/positions').then((result) => {
      setPositions(result.data.data)
    })
  }

  const getUserTypes = () => {
    axiosInstance.get('/usertypes').then((result) => {
      setUserTypes(result.data)
    })
  }

  useEffect(() => {
    getUserTypes()
    getPositions()
  }, [])


  const addUserbyAdmin = () => {
    employment_start_date_formatted()
    let formData = new FormData()
    
    formData.append("profile_photo",userObject.profile_photo)
    delete userObject.user_object['profile_photo']
    formData.append('user_object',JSON.stringify(userObject.user_object))
    formData.append('personel_info',JSON.stringify(userObject.personel_info))
    formData.append('other_info',JSON.stringify(userObject.other_info))
    formData.append('bank_info',JSON.stringify(userObject.bank_info))



    axiosInstance_multi.post('/admin/create/user', formData,).then((res) => {
      navigate('/adminpanel')
    }).catch((error) => {
      alert(error)
    })
  }

  const employment_start_date_formatted = () => {
    let formatted_start_date = employmentStartDate.start_year + "-" + employmentStartDate.start_month + "-" + employmentStartDate.start_day
    let formatted_end_date = employmentEndDate.end_year + "-" + employmentEndDate.end_month + "-" + employmentEndDate.end_day
    setUserObject((prevState) => {
      let copiedState = prevState
      copiedState['user_object']["employment_start_date"] = formatted_start_date
      copiedState['user_object']["employment_end_date"] = formatted_end_date
      return (copiedState)
    })
  }


  return (
    <div>
      <div className='employeedetailnavbar'>
        <TabBarForForms currentSection={currentSection} handleClick={handleClick} />
      </div>
      {currentSection === 1 && <GeneralForm is_admin={true} setEmploymentEndDate={setEmploymentEndDate} setEmploymentStartDate={setEmploymentStartDate} userObject={userObject} usertypes={usertypes} positions={positions} setUserObject={setUserObject} />}
      {/* {currentSection === 3 && <PersonelForm userObject={userObject} is_admin={true} setUserObject={setUserObject} />}
      {currentSection === 4 && <OtherForm userObject_state={userObject} is_admin={true} setUserObject={setUserObject} />}
      {currentSection === 5 && <BankForm userObject={userObject} is_admin={true} setUserObject={setUserObject} />}
      {currentSection === 6 && <SocialForm userObject={userObject} is_admin={true} setUserObject={setUserObject} />} */}
      <div className='btngroup'>
        <button onClick={addUserbyAdmin} className='updatebtn'>{t("Submit")}</button>
      </div>
    </div>
  );
}

export default EmployeeAddByAdmin;
