import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {  useNavigate, useParams } from 'react-router-dom';
import BankForm from '../components/forms/BankForm';
import GeneralForm from '../components/forms/GeneralForm';
import OtherForm from '../components/forms/OtherForm';
import PersonelForm from '../components/forms/PersonelForm';
import SocialForm from '../components/forms/SocialForm';
import TabBarForForms from '../components/forms/TabNavForForms';
import axiosInstance from '../helperFuncs/axiosInstance';
import {t} from "i18next";
import axiosInstance_multi from '../helperFuncs/axiosInstance_multi_data';

function EmployeeUpdateByEmployee() {
  const [currentSection, setCurrentSection] = useState(1);
  const [positions, setPositions] = useState([])
  const [usertypes, setUserTypes] = useState([])
  const [message, setMessage] = useState('');
  const [employmentStartDate, setEmploymentStartDate] = useState({})
  const [employmentEndDate, setEmploymentEndDate] = useState({})
  const [showLoading,setShowLoading]=useState(true)

  const navigate = useNavigate()
  const params = useParams()
  const userObject_slice = useSelector((state) => state.userSlice)
  const [userObject, setUserObject] = useState(null)

  const handleClick = (section) => {
    setCurrentSection(section);
  };

  const getPositions = () => {
    axiosInstance.get('/positions').then((result) => {
      setPositions(result.data.data)
    })
  }

  const getUserTypes = () => {
    axiosInstance.get('/usertypes').then((result) => {
      setUserTypes(result.data)
    })
  }

  const getUserDetail= ()=>{
    if(userObject == undefined || userObject == null){
      setUserObject({ user_object: {}, personel_info: {}, other_info: {}, bank_info: {} })

    }



    axiosInstance.get('/user/detail/'+userObject_slice.user._id).then((result)=>{
      setUserObject((prevState)=>{
        let copiedState = [prevState]
        copiedState['user_object']=result.data.user_object
        copiedState['personel_info']=result.data.personel_info
        copiedState['other_info']=result.data.other_info 
        copiedState['bank_info']=result.data.user_bank_account 
        return(copiedState)
    })
    setShowLoading(false)

    })
   
  }


  useEffect(() => {
    getUserDetail()
    getUserTypes()
    getPositions()
  }, [])


  const updateUserByAdmin = () => {
    employment_start_date_formatted()
      let formData = new FormData()
    formData.append("profile_photo",userObject.profile_photo)
    delete userObject.user_object['profile_photo']
    
    formData.append('user_object',JSON.stringify(userObject.user_object))
    formData.append('personel_info',JSON.stringify(userObject.personel_info))
    formData.append('other_info',JSON.stringify(userObject.other_info))
    formData.append('bank_info',JSON.stringify(userObject.bank_info))



    const userId = userObject_slice.user._id
    axiosInstance_multi.patch('/user/edit/'+userId, formData).then((res) => {
      navigate('/profile')
      setMessage({ text: 'Updated✔️', color: 'green' });
      setTimeout(() => setMessage(null), 5000);
      navigate('/profile')
    }).catch((error) => {
      setMessage({ text: 'Error❌', color: 'red' });
      setTimeout(() => setMessage(null), 5000);
    })
  }


  const employment_start_date_formatted = ()=>{
    let formatted_start_date = employmentStartDate.start_year + "-" + employmentStartDate.start_month + "-"+employmentStartDate.start_day
    let formatted_end_date = employmentEndDate.end_year + "-" + employmentEndDate.end_month + "-"+employmentEndDate.end_day
    setUserObject((prevState)=>{
      let copiedState = prevState
      copiedState['user_object']["employment_start_date"]=formatted_start_date
      copiedState['user_object']["employment_end_date"]=formatted_end_date
      return(copiedState)
  })

  }



  return (
    (userObject != null || userObject !=undefined) ? (
      <div>
                {showLoading && 
        <div style={{width:"100vw",height:"100vh",position:"fixed",background:"rgba(0,0,0,0.5)",display:"flex",justifyContent:"center",alignItems:"center"}}>
         
         
          <div style={{marginLeft:-350}}>

          <img   src='https://media.tenor.com/On7kvXhzml4AAAAj/loading-gif.gif'/>
          </div>

        </div>
        }
      <div className='employeedetailnavbar'>

        <TabBarForForms currentSection={currentSection} handleClick={handleClick} />

      </div>
      {currentSection === 1 && <GeneralForm is_admin={userObject_slice.user.is_admin} setEmploymentEndDate={setEmploymentEndDate} setEmploymentStartDate={setEmploymentStartDate} userObject={userObject} usertypes={usertypes} positions={positions} setUserObject={setUserObject} />}
      {currentSection === 3 && <PersonelForm userObject={userObject} is_admin={userObject_slice.user.is_admin}  personel_info={userObject.personel_info} setUserObject={setUserObject} />}
      {currentSection === 4 && <OtherForm userObject_state={userObject} is_admin={userObject_slice.user.is_admin}  other_info={userObject.other_info} setUserObject={setUserObject} />}
      {currentSection === 5 && <BankForm userObject={userObject} is_admin={userObject_slice.user.is_admin}  bank_info={userObject.bank_info}  setUserObject={setUserObject} />}
      {currentSection === 6 && <SocialForm  is_admin={userObject_slice.user.is_admin}  userObject={userObject}  setUserObject={setUserObject} />}
      <div className='btngroup'>
        <button onClick={updateUserByAdmin} className='updatebtn'>{t("Update")}</button>
        {message && <p style={{ color: message.color }}>{message.text}</p>}
      </div>
    </div>
    ) :null

  );
}

export default EmployeeUpdateByEmployee;
