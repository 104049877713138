import axios from "axios";

const axiosInstance = axios.create({
	// Configuration
	baseURL: process.env.REACT_APP_BACKEND_URL,
	timeout: 80000,
	headers: {
		Accept: 'application/json',
		Authorization: 'Token '+ window.localStorage.getItem('token'),
		// "Content-Type": "application/json",
		  
	},
});

export default axiosInstance