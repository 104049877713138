import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";

import userReducer from './user_reducer'
import userTypeSlices from './current_user_type_reducer'  
import {loadState} from "./localstorage";
import languageSlice from "./site_language";

const reducers = combineReducers({
  userSlice: userReducer.reducer,
  currentUserType:userTypeSlices.reducer,
  languageSlice:languageSlice.reducer
});

const store = configureStore({
  devTools: true,
  reducer: reducers,
  preloadedState: loadState(),
});

export default store; 
 