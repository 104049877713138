import logo from "../assets/images/logo.png"
import ProgressBar from "@ramonak/react-progress-bar";
import Table from 'react-bootstrap/Table';
import { useParams } from "react-router-dom";
import axiosInstance from "../helperFuncs/axiosInstance";
import { useEffect, useLayoutEffect, useState } from "react";
import {t} from "i18next";
import {Buffer} from 'buffer';
import { useSelector } from "react-redux";


export default function ReportsPage() {
    let { userId } = useParams();

    const [user,setUser]=useState({})
    const [leaveRequests,setLeaveRequests]=useState([])
    const [canUserGetLeaveRequest,setCanUserGetLeaveRequest]=useState(true)
    const [whenCanGetRequest,setWhenCanGetRequest]=useState(undefined)
    const [userHasRightsToHaveAnnualLeave,setUserHasRightsToHaveAnnualLeave]=useState(undefined)
    const [totalGainedAnnualLeave,setTotalGainedAnnualLeave]=useState(0)
    const [usedAnnualLeaves,setUsedAnnualLeaves]=useState(0)
    const [totalWorkingDays,setTotalWorkingDays]=useState(0)

    const userObject = useSelector((state) => state.userSlice)


    useLayoutEffect(() => {
        if (userObject.user.is_admin != true) {
            window.location.href = "/profile"
        }
    }, [])


    const getUserInfo = ()=>{
        axiosInstance.get('/user/detail/'+userId).then((res)=>{
            setUser(res.data)
            setTotalWorkingDays(res.data.totalWorkingDays)
        })
    }


    const getLeaveRequests = ()=>{
        axiosInstance.get('/requests/leave/'+userId).then((res)=>{
            setLeaveRequests(res.data.data)
            setUsedAnnualLeaves(res?.data?.leaves[0]?.sum)
            setCanUserGetLeaveRequest(res.data.canUserGetLeaveRequest)
            setUserHasRightsToHaveAnnualLeave(res.data.userHasRightsToHaveAnnualLeave)
            setWhenCanGetRequest(res.data.whenToGetLeaveRequest)
            setTotalGainedAnnualLeave(res.data.totalGainedAnnualLeave)
        })
    }

    useEffect(()=>{
        getUserInfo()
        getLeaveRequests()
    },[])

    const arrayToImageUrl = (profile_photo=undefined)=>{
        if(profile_photo !=undefined){
            let {contentType,data} =profile_photo 
            let bufferedData = (Buffer.from(data.data,"base64"))
            let imageurl = URL.createObjectURL(new Blob([bufferedData.buffer],{type:contentType}))
            return imageurl
        }
        return ""
    }


    return (
        <div>
            <div className="profile-page-info">
                <h1 className="profile-title" style={{ marginBottom: 30 }}>{t("Employee")}</h1>
                <div className="profile-picture" style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                    <img style={{maxHeight:"100%",maxWidth:"100%"}} src={arrayToImageUrl(user?.user_object?.profile_photo)}/>
                </div>
                <h1 className="profile-title">{t("Name")}:</h1>
                <p className="profile-text">{user?.user_object?.name} {user?.user_object?.surname}</p>
                <h1 className="profile-title">{t("Position")}:</h1>
                {user.user_object?.positions?.map((position)=>{
                    return(
                        <p key={position._id} className="profile-text">
                        {position.name}
                    </p>
                    )
                })}
               
                <h1 className="profile-title">{t("Type")}:</h1>
                <p className="profile-text">{user?.user_object?.working_type?.name}</p>
                <div style={{ display: "flex", gap: "150px", alignItems: "center", marginTop: 0 }}>
                    <div>
                        <h1 className="profile-title" style={{ fontSize: 22 }}>{t("Start working day")}</h1>
                        <p className="profile-text">{user?.user_object?.employment_start_date}</p>
                    </div>
                {user?.user_object?.employment_start_date && 
                    <div >
                        <h1 className="profile-title">Employment end date:</h1>
                        <p className="profile-text">{user.user_object.employment_end_date}</p>
                    </div>
                    }
                </div>
                <div style={{ marginTop: "120px", marginBottom: "120px" }}>
                    <h1 className="profile-title" style={{ fontSize: 22 }}>{t("Leave Days")}:</h1>
                    <div style={{ marginBottom: "15px", marginTop: "40px" }}>
                    <p style={{ fontWeight: "bold", marginLeft:"27vh" }}>{usedAnnualLeaves ? usedAnnualLeaves : 0} {t("Days left out of")} {totalGainedAnnualLeave} {t("days")}</p>
                        <ProgressBar
                        className='profile-page-wrapper '
                        maxCompleted={totalGainedAnnualLeave}
                        completed={usedAnnualLeaves ? usedAnnualLeaves: 0}


                        bgColor="#FC4F5A"
                        height="30px"
                        isLabelVisible={false}
                        labelColor="#e80909"
                    />
                    <p style={{ fontWeight: "bold", marginTop: "15px", marginLeft:"30vh" }}>{usedAnnualLeaves ? usedAnnualLeaves: 0}  {t("Days used")}</p>
                    </div>

                </div>
                {leaveRequests?.length >0 && 
                <>
                <h1 className="profile-title" style={{ marginBottom: 35, fontSize: 22 }}>{t("Leave Requests")}:</h1>
                <Table responsive="md" style={{ maxWidth: "50%", marginBottom: 60, marginLeft: 20 }}>
                    <thead>
                        <tr>

                        </tr>
                    </thead>
                    <tbody>
                        {leaveRequests.map((item,index)=>{
                            return(
                                <tr key={index} style={{ borderTop: "1px solid #dee2e6" }}>
                                <td style={{ fontWeight: 500}}>{item.start_date} -- {item.end_date} </td>
                                {item.approved == null ? 
                            <td style={{ color: "#ACB9FF", fontWeight: "bold"}}>{t("Pending")}</td>
                            : item.approved ? (
                                <td style={{ color: "#00BB83", fontWeight: "bold"}}>{t("Approved")}</td>

                            ):
                            (<td style={{ color: "#F00000", fontWeight: "bold"}}>{t("Rejected")}</td>)
                            
                            }
    
                            </tr>
                            )
                        })}
                      
                    </tbody>
                </Table>
                </>
                }
                <h1 className="profile-title" style={{ fontSize: 22, marginBottom: 30 }}>{t("Total Working Days")}: {totalWorkingDays}</h1>
            </div>
        </div>
    )
}