import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  
    "user": {
        "is_admin":false,
        "is_manager":false,
        "is_employee":false,
        "_id": "",
        "name": "",
        "surname": "",
        "password": "",
        "email": "",
        "email2": "",
        "profile_photo": "",
        "phone": "",
        "phone2": "",
        "user_types": [],
        "positions": [],
        "working_type": null,
        "employment_start_date": null,
        "employment_end_date": null,
        "twitter_link": "",
        "linkedin_link": "",
        "discord_link": "",
    },
}

export const userSlice = createSlice({
  name: 'UserObject',
  initialState,
  reducers: {
    setUserObject: (state,action) => {
        state.user = action.payload.userObject

    },

  },
})

// Action creators are generated for each case reducer function
export const { setUserObject } = userSlice.actions

export default userSlice
