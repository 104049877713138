import React from 'react';
import "./Request.css";
import {t} from "i18next";

function Request() {
  return (
    <>
        <div className="row py-2" style={{justifyContent:"center"}}>
            <div className="col-12 reqblock" style={{width:"95%"}}>
                <div className="row h-100">
                    <div className="col-2">
                        <img width={90} height={90} src="" alt="" style={{backgroundColor:"#D9D9D9", borderRadius:"50%"}} className="mt-5" />
                    </div>
                    <div className="col-5">
                        <div className="row h-100 coltwo" style={{alignItems:"center",}}>
                            <div className="col-12 ">
                                <h5 className='pt-3' style={{fontSize:"18px"}}>{t("Name")}:</h5>
                            </div>
                            <div className="col-12">
                                <h5 style={{fontSize:"18px"}}>{t("Duration")}:</h5>
                            </div>
                            <div className="col-12">
                                <h5 style={{fontSize:"18px"}}>{t("Reason")}:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="row h-100">
                            <div className="col-12">
                                <h5 className='pt-5' style={{fontSize:"18px"}}>{t("Date requested")}:</h5>
                            </div>
                            <div className="col-12 d-flex" style={{justifyContent:"end",alignItems:"center"}}>
                                <div className='h-50 pe-5'>
                                    <button className='btn text-white' style={{backgroundColor:"#17B5AD",}}>{t("Accept")}</button>
                                    <button className='btn text-white' style={{backgroundColor:"#FC4F5A",marginLeft:"15px"}}>{t("Reject")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Request