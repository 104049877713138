import './App.css';
import Sidebar from "./components/sidebar/Sidebar"
import LoginPage from './Pages/LoginPage';
import ProfilePage from './Pages/ProfilePage';
import RequestPage from './Pages/RequestPage';
import CalendarPage from './Pages/CalendarPage';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import EmployeesPage from './Pages/EmployeesPage';
import Notifications from './Pages/Notifications';
import AdminProfile from './Pages/AdminProfile';
import AdminPanel from './Pages/AdminPanel';
import ReportsPage from './Pages/ReportsPage';
import LeaveRequest from './Pages/LeaveRequest';
import axiosInstance from './helperFuncs/axiosInstance';
import EmployeeUpdateByAdmin from './Pages/EmployeeUpdateByAdmin';
import EmployeeAddByAdmin from './Pages/EmployeeAddByAdmin';
import EmployeeUpdateByEmployee from './Pages/EmployeeUpdateByEmployee';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from './assets/translations/en/en.js'
import { tr } from './assets/translations/tr/tr.js'
import { useSelector } from 'react-redux';
import UserNotifications from './Pages/UserNotifications';

const script1 = document.createElement("script");

script1.src = "https://cdn.jsdelivr.net/npm/jquery@3.4.1/dist/jquery.min.js";
script1.async = false;
document.body.appendChild(script1);



export default function App() {
  const {language} = useSelector((state) => state.languageSlice)
  const location = useLocation()
  const navigate = useNavigate()

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: {
        en: {
          translation:
            en

        },
        tr: {
          translation:
            tr

        },
      },
      lng: language, 
      fallbackLng: "en",

      interpolation: {
        escapeValue: false 
      }
    });


  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error?.response?.status === 401) {

      } else if (error?.response?.status === 400) {

      } else if (error?.response?.status === 403) {
        navigate('login', { replace: true })

      } else if (error?.response?.status === 404) {

      } else if (error?.response?.status === 500) {

      } else if (error?.response?.status === 505) {

      }
      return Promise.reject(error);
    }
  );



  return (

    <div className='row' style={{ width: "100%", height: "100%" }}>
      {location.pathname !== "/login" && <div className='col-sm-4 col-md-3 col-lg-2 col-xl-2 col-4 px-0'><Sidebar /></div>}
      <div className='col-sm-8 col-md-9 col-lg-10 col-xl-10 col-8 px-0'>
        <Routes>
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/adminprofile" element={<AdminProfile />} />
          <Route exact path="/adminpanel" element={<AdminPanel />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/request" element={<RequestPage />} />
          <Route exact path="/calendar" element={<CalendarPage />} />
          <Route exact path="/admin/employee" element={<EmployeeAddByAdmin />} />
          <Route exact path="/admin/employee/:user_id" element={<EmployeeUpdateByAdmin />} />
          <Route exact path="/user/update" element={<EmployeeUpdateByEmployee />} />
          <Route exact path="/employees" element={<EmployeesPage />} />
          <Route exact path="/notifications" element={<Notifications />} />
          <Route exact path="/user/notifications" element={<UserNotifications />} />
          <Route exact path="/reports/:userId" element={<ReportsPage />} />
          <Route exact path="/leaverquests" element={<LeaveRequest />} />
        </Routes>
      </div>
    </div>
  );
}


