import React from 'react';
import "./SocialForm.css";
import UpdateNavbar from '../UpdateNavbar/UpdateNavbar';
import { FaDiscord } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { useEffect, useState } from "react";
import axiosInstance from '../../helperFuncs/axiosInstance';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

function SocialForm({setUserObject,userObject}) {

    
    const [data,setData]=useState({})

    const getSocialInfo = ()=>{
        axiosInstance.get('/user/detail').then((result)=>{
            setData(result.data)
        }).catch((error)=>{
            alert.error(<div style={{color:"white"}}>error</div>)
        })
    }

    useEffect(()=>{
        getSocialInfo()
    },[])

    const handleUserObjectData = (e)=>{
        let key_object = e.target.name
        let value = e.target.value
  
        setUserObject((prevState)=>{
            let copiedState = prevState
            copiedState['user_object'][key_object]=value
            return(copiedState)
        
        })
    }



  return (
    <>
    {/* <UpdateNavbar />  */}
    <div className='GeneralForm'>
        <div className='social-media-main'>

            <InputGroup className="mb-3" style={{maxWidth:"80%"}}>
          
                <InputGroup.Text id="basic-addon1"><FaDiscord /></InputGroup.Text>
                <Form.Control
                name='discord_link'
                onChange={handleUserObjectData}
                aria-describedby="basic-addon1"
                className='borderRadius-none'
                placeholder={userObject?.user_object?.discord_link}
                />
            </InputGroup>
            <InputGroup className="mb-3" style={{maxWidth:"80%"}}>
          
                <InputGroup.Text id="basic-addon1"><FaLinkedinIn /></InputGroup.Text>
                <Form.Control
                name='linkedin_link'
                onChange={handleUserObjectData}
                aria-describedby="basic-addon1"
                className='borderRadius-none'
                placeholder={userObject?.user_object?.linkedin_link}
                />
            </InputGroup>
            <InputGroup className="mb-3" style={{maxWidth:"80%"}}>
           
                <InputGroup.Text id="basic-addon1"><FaTwitter /> </InputGroup.Text>
                <Form.Control
                name='twitter_link'
                onChange={handleUserObjectData}
                aria-describedby="basic-addon1"
                className='borderRadius-none'
                placeholder={userObject?.user_object?.twitter_link}
                />
            </InputGroup>
        </div>
      
       
    </div>
    </>
    
  )
}

export default SocialForm