import logo from "../assets/images/logo.png"
import axiosInstance from "../helperFuncs/axiosInstance";
import { setUserObject } from "../redux_related/user_reducer"
import { useAlert } from "react-alert";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { useDispatch } from 'react-redux';


export default function LoginPage() {
    const alert = useAlert()
    const dispatch = useDispatch()


    const responseGoogle = (response) => {
        axiosInstance.post('/account/google/login', {token: response.credential})
          .then((resultData) => {
            let result_object = JSON.stringify(resultData.data)
            let result = JSON.parse(result_object)

            window.localStorage.removeItem('token')
            window.localStorage.setItem('redux', "")

            let is_admin = result.user.user_types.map((type) => type.name.toLowerCase() == "admin")[0] || false
            let is_employee = result.user.user_types.map((type) => type.name.toLowerCase() == "employee")[0] || true
            let is_manager = result.user.user_types.map((type) => type.name.toLowerCase() == "manager")[0] || false
            let token = result.token
            let user_obj = result.user
            user_obj['is_admin'] = is_admin
            user_obj['is_employee'] = is_employee
            user_obj['is_manager'] = is_manager



            dispatch(setUserObject({ userObject: user_obj }))


            while (window.localStorage.getItem('token') == null) {
                window.localStorage.setItem('token', token)

            }


            if (is_admin){
                window.location.href="/notifications"
            }
            else if(is_manager ){
                window.location.href="/manager"

            }
            else{
                window.location.href="/profile"

            }
          });
    };


    return (
        <div className="login-page" style={{ width: "100vw", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", marginTop: 100 }}>
            <img src={logo} alt="audteye-logo" className="audteye-logo" />
            <div id="sub_container_login" >
                <div id="login_with_dashed">
                    <h2 className="first-card-title">Login</h2>
                    <div className="login-page-form">
                        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                            <GoogleLogin
                                onSuccess={credentialResponse => {
                                    responseGoogle(credentialResponse)
                                }}
                                onError={() => {
                                    alert('Login failed')
                                }}
                            />
                        </GoogleOAuthProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

