import React, { useEffect, useLayoutEffect, useState } from 'react'
import RequestTwo from '../components/notificationcomp/RequestTwo'
import "../components/notificationcomp/Request2.css"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Multiselect from 'multiselect-react-dropdown';
import Form from 'react-bootstrap/Form';
import axiosInstance from '../helperFuncs/axiosInstance';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useSelector } from 'react-redux';
import { t } from "i18next";


export default function LeaveRequest() {
    const [userList, setUserList] = useState([])
    const [leaves, setLeaves] = useState([])
    const [maxPage, setMaxPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentTab, setCurrentTab] = useState(undefined)
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selectedUserIds,setSelectedUserIds]=useState([])
    const [recordStatus,setRecordStatus]=useState(false)
    const [excelDownloadLink,setExcelDownloadLink]=useState(undefined)
    const [hostname,setHostname]=useState("")

    const userObject = useSelector((state) => state.userSlice)


    useLayoutEffect(() => {
        if (userObject.user.is_admin != true) {
            window.location.href = "/profile"
        }
    }, [])


    window.addEventListener('click', function (e) {
        var clickedTab = e.target.id;

        if (clickedTab === 'uncontrolled-tab-example-tab-approved') {
            document.getElementById(clickedTab).classList.remove('active')
            document.getElementById(clickedTab).classList.add('approved')

        } else if (clickedTab === 'uncontrolled-tab-example-tab-pending') {
            document.getElementById(clickedTab).classList.remove('active')
            document.getElementById(clickedTab).classList.add('pending')

        } else if (clickedTab === 'uncontrolled-tab-example-tab-rejected') {
            document.getElementById(clickedTab).classList.remove('active')
            document.getElementById(clickedTab).classList.add('rejected')
        }
    });


    const getAllLeaveRequests = () => {
        
        axiosInstance.get('/admin/leaves/all?page=' + currentPage + "&type=" + currentTab).then((res) => {
            setMaxPage(res.data.total_page)
            setHostname(res.data.hostname)
            setLeaves(res.data.data)
        })
    }

    useEffect(() => {
        getAllLeaveRequests()
    }, [currentPage, currentTab])

    let users_list = []

    const getAllUsers = () => {
        axiosInstance.get('/admin/leaves/has/users').then((res) => {
            Array.from(res.data?.data).forEach((user) => {
                let obj = {
                    label: user?.name + " " + user?.surname,
                    value: user?._id,
                }
                users_list.push(obj)
            })
            setUserList(users_list)

        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const options = userList.map((user, index) => ({
        value: user.value,
        label: user.label
    }));

    const convertDate = (date)=>{
        let parsedDate = new Date(Date.parse(date))
        let year = parsedDate.getFullYear()
        let month = parsedDate.getMonth()+1
        let day = parsedDate.getDate()
        let formattedDate = year + "-"+month + "-"+day
        return formattedDate
    }


    const prepareExcelForDownload = ()=>{
        setRecordStatus(false)
        let userIdsMapped = selectedUserIds.map((item)=>item.value)
        if(startDate!=undefined || endDate !=undefined){
            axiosInstance.post(`/reports/excel/download?start=${convertDate(startDate)}&end=${convertDate(endDate)}`,{"user_ids":userIdsMapped,start:convertDate(startDate),end:convertDate(endDate)}).then((res)=>{
                setExcelDownloadLink(res.data.hostname + res.data.link)
                setRecordStatus(!res.data.status)

            })
        }


    }

    return (
        <div>
            <div className='row px-5'>
                <div className="col-12">
                    <h2 style={{ fontSize: "26px", fontWeight: "700", marginTop: "90px", marginBottom: "60px" }}>{t("Leave Requests")}</h2>
                </div>
                <Tabs
                    defaultActiveKey="all"
                    id="uncontrolled-tab-example"
                    style={{ width: "99%", display: "flex", justifyContent: "space-between" }}
                    onClick={(e) => {
                        let currentFromTarget = e.target.getAttribute('data-rr-ui-event-key')
                        if (currentFromTarget == "all") {
                            setCurrentTab(undefined)

                        }
                        else {
                            setCurrentTab(e.target.getAttribute('data-rr-ui-event-key'))

                        }
                        setCurrentPage(1)
                    }}
                >
                    <Tab eventKey="all" title={t("All")}>
                        <div className="col-12 d-flex" style={{ flexDirection: "column", backgroundColor: "#F0F0F0", paddingTop: "15px", paddingBottom: "15px" }}>
                            {leaves.map((item,index) => {
                                return (
                                    <RequestTwo key={index} hostname={hostname}  leave={item}></RequestTwo>
                                )
                            })}

                        </div>
                    </Tab>
                    <Tab eventKey="approved" title={t("Approved")}>
                        <div className="col-12 d-flex" style={{ flexDirection: "column", backgroundColor: "#00BB83", paddingTop: "15px", paddingBottom: "15px" }}>
                            {leaves.map((item,index) => {
                                return (
                                    <RequestTwo key={index} hostname={hostname}  leave={item}></RequestTwo>
                                )
                            })}
                        </div>
                    </Tab>
                    <Tab eventKey="pending" title={t("Pending")}>
                        <div className="col-12 d-flex" style={{ flexDirection: "column", backgroundColor: "#FECB47", paddingTop: "15px", paddingBottom: "15px" }}>
                            {leaves.map((item,index) => {
                                return (
                                    <RequestTwo key={index} hostname={hostname}  leave={item}></RequestTwo>
                                )
                            })}
                        </div>
                    </Tab>
                    <Tab eventKey="rejected" title={t("Rejected")}>
                        <div className="col-12 d-flex" style={{ flexDirection: "column", backgroundColor: "#F00000", paddingTop: "15px", paddingBottom: "15px" }}>
                            {leaves.map((item,index) => {
                                return (
                                    <RequestTwo key={index} hostname={hostname} leave={item}></RequestTwo>
                                )
                            })}
                        </div>
                    </Tab>
                </Tabs>
                <PaginationControl
                    page={currentPage}
                    between={4}
                    total={maxPage}
                    limit={1}
                    changePage={(page) => {
                        setCurrentPage(page)
                    }}
                    ellipsis={1}
                />
                <div className="col-12" style={{ marginTop: 50 }}>
                    <h2 style={{ fontSize: "27px", fontWeight: "700", paddingTop: "15px", paddingLeft: "37px" }}>{t("Download Leaves’ Report")}</h2>
                </div>
                <div className="col-12" style={{ marginTop: 35 }}>
                    <div className="row">
                        <div className="col-4">
                            <h2 style={{ fontSize: "16px", fontWeight: "400", paddingTop: "15px", paddingLeft: "37px" }}>{t("Select user")}:</h2>
                        </div>
                        {/* {recordStatus && 
                        <h4>There is no record with given parameters !</h4>
                    } */}
                        <div className="col-6" style={{ width: "50vh" }}>

                            <Multiselect
                                options={options}
                                selectedValues={selectedOptions}
                                showCheckbox={true}
                                onSelect={(e)=>{
                                    setSelectedUserIds((prevstate)=>{
                                        let copiedstate = prevstate
                                        copiedstate = e
                                        return copiedstate
                                    })
                                }}
                                style={{ chips: { background: "#6666ff", color: "white !important" } }}
                                placeholder={t("Select")}
                                displayValue="label"
                            />

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <h2 style={{ fontSize: "16px", fontWeight: "400", paddingTop: "15px", paddingLeft: "37px" }}>{t("Download Leave Requests for the last")}:</h2>
                        </div>
                        <div className="col-6" style={{ marginTop: 20 }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 35, marginTop: 15 }}>
                                <div>
                                    <p>
                                        {t("Start Date")}
                                    </p>
                                    <Form.Group controlId="formGroupEmail" style={{ width: "100%"}}>
                                        <Form.Control type="date" placeholder="07" value={startDate} onChange={(event) => setStartDate(event.target.value)} className='input-bg' />
                                    </Form.Group>
                                </div>
                                <div>
                                    <p>
                                        {t("End Date")}
                                    </p>
                                    <Form.Group controlId="formGroupEmail" style={{ width: "100%" }}>
                                        <Form.Control type="date" placeholder="09" value={endDate} onChange={(event) => setEndDate(event.target.value)} className='input-bg' />
                                    </Form.Group>
                                </div>
                            </div>
                            <div style={{display:"flex",alignItems:"center",paddingTop:25,gap:5,marginBottom:18}}>
                            <button onClick={()=>{prepareExcelForDownload()}} className='btn text-white' style={{ padding: "11px 35px", borderRadius: "8px", backgroundColor: "#17B5AD", fontWeight: "700", border: "none",}}>{t("Prepare Excel")}</button>
                            {(excelDownloadLink?.length >3)&&
                            <a className="download-excel-btn" href={excelDownloadLink}>{t("Download Excel")}</a>
                        }
                        {recordStatus && (<div style={{color:"red"}}>There is no record with given parameters !</div>)}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
