import React from 'react'
import { useEffect, useState } from 'react';

import { SidebarData, AdminSidebarData } from './SidebarData';
import { FaRecordVinyl } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { t } from "i18next";

import axiosInstance from '../../helperFuncs/axiosInstance';

import { useNavigate } from "react-router-dom";
import { setLanguage } from '../../redux_related/site_language';

import { FaSignOutAlt } from "react-icons/fa";


function Sidebar() {

  const [userType, setUserType] = useState(null)
  const [sidedata, setSideData] = useState([])
  const [showRedDot, setShowRedDet] = useState(false)
  const [totalRecord, setTotalRecord] = useState(0)
  const [totaleventsCount, setTotalEventsCount] = useState(0)

  const userObject = useSelector((state) => state.userSlice)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  useEffect(() => {

    if (userObject.user.is_admin == true) {
      getNotificationCount()

      setSideData(AdminSidebarData)
      setUserType('admin');

    } else {
      getNotificationCountForEvents()
      getNotificationCountForEmployee()
      setSideData(SidebarData)

      setUserType('user');
    }
  }, []);

  const getNotificationCount = () => {
    axiosInstance.get('/admin/leaves/all?page=' + 1 + "&type=" + "pending").then((res) => {
      setTotalRecord(res.data.total_record)
      let notificationCount = window.localStorage.getItem('notificationCount')
      if (notificationCount === null) {
        window.localStorage.setItem('notificationCount', 0)
      }
      if (window.localStorage.getItem('notificationCount') != res.data.total_record) {

        setShowRedDet(true)
      }
    })
  }

  const getNotificationCountForEmployee = () => {
    axiosInstance.get('/requests/notifications/counts/leave').then((res) => {
      setTotalRecord(res.data.nonNullCount)

      let notificationCount = window.localStorage.getItem('notificationCount')
      if (notificationCount === null) {
        window.localStorage.setItem('notificationCount', 0)
      }
      if (window.localStorage.getItem('notificationCount') != res.data.nonNullCount) {

        setShowRedDet(true)
      }

    })
  }

  const getNotificationCountForEvents = ()=>{
    axiosInstance.get('/events/notifications/events/count').then((res)=>{
      setTotalEventsCount(res.data.eventsCount)

      let notificationCount = window.localStorage.getItem('eventCount')
      if (notificationCount === null) {
        window.localStorage.setItem('eventCount', 0)
      }
      if (window.localStorage.getItem('eventCount') != res.data.eventsCount) {

        setShowRedDet(true)
      }

    })
  }



  return (
    <div className='sidebar '>
      <div className="sidebar-top border-bottom">
        <div className='sidebar-notifications'>
          <h2 className="sidebar-notifications">


            <div style={{ position: 'relative' }}>
              {showRedDot &&
                <div style={{ position: 'absolute', background: "red", padding: 5, borderRadius: "50%", top: -10, right: -15 }}></div>
              }
            </div>
            {userObject.user.is_admin == true ?
              <>
                <FaRecordVinyl />


                <button onClick={() => {
                  window.localStorage.setItem('notificationCount', totalRecord)
                  setShowRedDet(false)

                  navigate('/notifications')
                }
                } className='text-white' style={{ textDecoration: "none", outline: "none", border: "none", background: "none" }} >
                  {t("Notifications")}
                </button>
              </>
              :
              <>
                <FaRecordVinyl />

                <div onClick={() => {
                  window.localStorage.setItem('eventCount', totaleventsCount)

                  window.localStorage.setItem('notificationCount', totalRecord)
                  setShowRedDet(false)

                  navigate('/user/notifications')
                }}>
                  {t("Notifications")}
                </div>
              </>
            }
          </h2>


        </div>

        <div className="sidebar-language">
          <p onClick={() => { dispatch(setLanguage({ language: "tr" })) }}>TR</p>
          <p onClick={() => { dispatch(setLanguage({ language: "en" })) }}>EN</p>
        </div>
      </div>

      <ul className='sidebar-list'>
        {(sidedata).map((val, key) => {
          return (
            <li
              key={key}
              className="row1 border-bottom"
              onClick={() => window.location.pathname = val.link}
            // style={{borderBottom:"1px solid white",width:"90%"}}
            >
              <div id='sidebar-icon'>{val.icon}</div><div id='sidebar-title'>{t(val.title)}</div>
            </li>
          )
        })}
      </ul>

      <div className='sidebar-notifications' style={{ marginLeft: 30, marginTop: 200 }}>
        <h2 className="sidebar-notifications">
          <>
            <FaSignOutAlt style={{ width: 18, color: "white" }} />
            <button onClick={() => {
              localStorage.removeItem('token');

              navigate('/login')
            }
            } className='text-white' style={{ textDecoration: "none", outline: "none", border: "none", background: "none", fontSize: 16 }} >
              {t("Log Out")}
            </button>
          </>
        </h2>
      </div>
    </div>
  )
}

export default Sidebar