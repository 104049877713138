import React from 'react';
import "./GeneralForm.css";
import { useEffect, useState } from "react";
import axiosInstance from '../../helperFuncs/axiosInstance';
import { t } from "i18next";


function GeneralForm({ setUserObject, positions, usertypes, userObject, setEmploymentStartDate, setEmploymentEndDate, is_admin }) {

    const [workingTypes, setWorkingTypes] = useState([])



    const arrangeFormattedDate = (e) => {
        let key_object = e.target.name
        let value = e.target.value

        if (key_object.includes('start')) {

            setEmploymentStartDate((prevState) => {
                let copiedState = prevState
                copiedState[key_object] = value
                return (copiedState)
            })
        }

        else if ((key_object.includes('end'))) {
            setEmploymentEndDate((prevState) => {
                let copiedState = prevState
                copiedState[key_object] = value
                return (copiedState)
            })
        }
    }



    useEffect(() => {
        getWorkingTypes()

    }, [])


    const getWorkingTypes = () => {
        axiosInstance.get('/workingtypes').then((res) => {
            setWorkingTypes(res.data.data)
        })
    }


    const handleUserObjectData = (e) => {
        let key_object = e.target.name
        let value = e.target.value

        if (e.target.name == "profile_photo") {
            setUserObject((prevState) => {

                let copiedState = prevState
                copiedState["profile_photo"] = e.target.files[0]
                return (copiedState)
            })
        }
        else {
            setUserObject((prevState) => {
                let copiedState = prevState
                copiedState['user_object'][key_object] = value
                return (copiedState)
            })
        }


    }


    return (
        <>

            <div style={{ paddingTop: 0 }} className='GeneralForm'>
                <div className='form1'>
                    <div className='item1'>
                        <label htmlFor="">{t("Name")}:</label>
                        <input disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.user_object?.name} type="text" name="name" id="name" />

                    </div>
                    <div className='item2'>
                        <label htmlFor="">{t("Surname")}:</label>
                        <input disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.user_object?.surname} type="text" name="surname" id="surname" />
                    </div>
                    <div className='item3'>
                        <label htmlFor="">Email:</label>
                        <input style={{ background: "#E4E8FE" }} disabled={!is_admin} onChange={handleUserObjectData} placeholder={userObject.user_object?.email} type="email" name="email" id="email" />
                    </div>
                    <div className='item4'>
                        <label htmlFor="">Email2:</label>
                        <input onChange={handleUserObjectData} placeholder={userObject.user_object?.email2} type="email" name="email2" id="email2" />
                    </div>
                    <div className='item5'>
                        <label htmlFor="">{t("Phone")}:</label>
                        <input
                            style={{ background: "#E4E8FE" }}
                            disabled={!is_admin}
                            onChange={handleUserObjectData}
                            placeholder={userObject.user_object?.phone}
                            type="number"
                            name="phone"
                            id="phone"
                            maxLength={11}
                        />
                    </div>
                    <div>
                        <label htmlFor="">{t("Photo")}:</label>
                        <input disabled={!is_admin} style={{ paddingTop: 5, background: "#E4E8FE" }} onChange={handleUserObjectData} type={"file"} name="profile_photo" />
                    </div>
                    <div className='item6'>
                        <label htmlFor="">{t("Phone")} 2:</label>
                        <input onChange={handleUserObjectData} placeholder={userObject.user_object?.phone2} type="number" name="phone2" id="phone2"  maxLength={11}/>
                    </div>
                    <div className='dateForm1'>
                        <div className='label1'>
                            <label htmlFor="">{t("Employment start date")}:</label>
                        </div>
                        <div className='item7'>
                            <label htmlFor="">{t("Month")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={
                                isNaN((new Date(Date.parse(userObject.user_object?.employment_start_date))?.getMonth() + 1)) ? "" : (new Date(Date.parse(userObject.user_object?.employment_start_date))?.getMonth() + 1)


                            } type="number" name="start_month" />
                        </div>
                        <div className='item8'>
                            <label htmlFor="">{t("Day")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={
                                isNaN(new Date(Date.parse(userObject.user_object?.employment_start_date))?.getDate()) ? "" : new Date(Date.parse(userObject.user_object?.employment_start_date))?.getDate()
                            } type="number" name="start_day" />
                        </div>
                        <div className='item9'>
                            <label htmlFor="">{t("Year")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={
                                isNaN(new Date(Date.parse(userObject.user_object?.employment_start_date))?.getFullYear()) ? "" : new Date(Date.parse(userObject.user_object?.employment_start_date))?.getFullYear()
                            } type="number" name="start_year" />
                        </div>
                    </div>
                    <div className='dateForm2'>
                        <div className='label2'>
                            <label htmlFor="">{t("Contract end date")}:</label>
                        </div>
                        <div className='item10'>
                            <label htmlFor="">{t("Month")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={
                                isNaN(new Date(Date.parse(userObject.user_object?.employment_end_date))?.getMonth() + 1) ? "" : new Date(Date.parse(userObject.user_object?.employment_end_date))?.getMonth() + 1


                            } type="number" name="end_month" id="" />
                        </div>
                        <div className='item11'>
                            <label htmlFor="">{t("Day")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={
                                isNaN(new Date(Date.parse(userObject.user_object?.employment_end_date))?.getDate()) ? "" : new Date(Date.parse(userObject.user_object?.employment_end_date))?.getDate()
                            } type="number" name="end_day" id="" />
                        </div>
                        <div className='item12'>
                            <label htmlFor="">{t("Year")}</label>
                            <input disabled={!is_admin} onChange={arrangeFormattedDate} placeholder={


                                isNaN(new Date(Date.parse(userObject.user_object?.employment_end_date))?.getFullYear()) ? "" : new Date(Date.parse(userObject.user_object?.employment_end_date))?.getFullYear()

                            } type="number" name="end_year" id="" />
                        </div>
                    </div>
                    <div className='item13'>
                        <label htmlFor="">{t("Working type")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name="working_type" style={{ width: "90%", height: "55%", border: "1px solid #6666FF" }}>
                            <option hidden>{userObject?.user_object?.working_type?.name != undefined ? userObject?.user_object?.working_type?.name : t("Select")}</option>
                            {workingTypes.map((type, index) => {

                                return (
                                    <option key={type._id} value={type._id}>{type.name}</option>
                                )
                            })}

                        </select>
                    </div>
                    <div className='item13'>
                        <label htmlFor="">{t("Positions")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name="positions" style={{ width: "90%", height: "70%", border: "1px solid #6666FF" }}>
                            <option hidden>{userObject?.user_object?.positions?.length > 0 ? userObject?.user_object?.positions[0]?.name : t("Select")}</option>
                            {positions.map((position) => {
                                return (
                                    <option key={position._id} value={position._id}>{position.name}</option>
                                )
                            })}

                        </select>
                    </div>
                    <div className='item14'>
                        <label htmlFor="">{t("User Types")}:</label>
                        <select disabled={!is_admin} onChange={handleUserObjectData} name="user_types" style={{ width: "90%", height: "70%", border: "1px solid #6666FF" }}>
                            <option hidden>{userObject?.user_object?.user_types?.length > 0 ? userObject?.user_object?.user_types[0]?.name : "Select"}</option>
                            {usertypes?.map((usertype) => {
                                return (
                                    <option key={usertype._type} value={usertype._id}>{usertype.name}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>

            </div>
        </>

    )
}

export default GeneralForm