export let tr = {
    "Notifications": "Bildirimler",
    "Admin Profile":"Yönetici Profil",
    "Admin Panel":"Yönetici Panel",
    "Admin Leave Requests":"Yönetici İzin Talepleri",
    "Calendar":"Takvim",
    "Profile":"Profil",
    "Request a leave":"İzin Talebi",
    "Name":"İsim",
    "Position":"Pozisyon",
    "Type":"Kullanıcı Türü",
    "Employment start date":"İşe başlama tarihi",
    "Contract end date":"Sözleşme bitiş tarihi:",
    "Leave Days":"İzin Günleri",
    "Social Media Accounts":"Sosyal Medya Hesapları",
    "4 Days left out of 14":"14 günden 4 gün kaldı",
    "10 Days used":"10 Gün kullanıldı",
    "Employees":"Çalışanlar",
    "Add information to be shown in the report of each user":"Her kullanıcının raporunda gösterilecek bilgileri ekleyin",
    "New employee profile":"Yeni çalışan profili",
    "Add new employee":"Yeni çalışan ekle",
    "Log Out":"Çıkış Yap",
    "Current employees":"Mevcut çalışanlar",
    "Show Profile":"Profili Göster",
    "Edit":"Düzenle",
    "Delete":"Sil",
    "Add Position":"Pozisyon Ekle",
    "Add position type to your employees":"Çalışanlarınıza pozisyon türü ekleyin",
    "Add Working Type":"Çalışma Türü Ekle",
    "Add working type to your employees":"Çalışanlarınıza çalışma türü ekleyin",
    "Add Leave Reason":"İzin Nedeni Ekle",
    "Add leave reason to be shown in the report of each user":"Her kullanıcının raporunda gösterilecek izin nedeni ekleyin",
    "Add Events":"Etkinlik Ekle",
    "Add company events and share them with your employees":"Şirket etkinliklerini ekleyin ve çalışanlarınızla paylaşın",
    "Performance Report":"Performans Raporu",
    "Add information to be shown in the report of each other":"Raporda gösterilecek bilgileri ekleyin",
    "New Position":"Yeni Pozisyon",
    "Positions":"Pozisyonlar",
    "Update":"Güncelle",
    "Select":"Seç",
    "New Working Type":"Yeni Çalışma Türü",
    "Working Type":"Çalışma Türü",
    "Working Types":"Çalışma Türleri",
    "New Leave Reason":"Yeni İzin Nedeni",
    "Leave Reason Types":"İzin Nedeni Türleri",
    "Leave Reason":"İzin Nedeni",
    "Event Title":"Etkinlik Adı",
    "Add here":"Buraya ekle",
    "Event Description":"Etkinlik Hakkında",
    "Text here":"Buraya yaz",
    "Start Date":"Başlangıç Tarihi",
    "End Date":"Bitiş Tarihi",
    "Add PDF":"PDF Ekle",
    "Upload":"Yükle",
    "Publish Event":"Etkinlik Oluştur",
    "Employee Name":"Çalışan Adı",
    "Send a Note":"Not Ekle",
    "Month":"Ay",
    "Year":"Yıl",
    "Add a Performance Badge":"Performans Rozeti Ekle",
    "Add a Report":"Rapor Ekle",
    "Leave Requests":"İzin Talepleri",
    "Duration":"Süre",
    "Reason":"Sebep",
    "Total Day":"Toplam Gün",
    "Accepted":"Kabul edildi",
    "Accept":"Kabul Et",
    "Rejected":"Reddedildi",
    "Reject":"Reddet",
    "Date requested":"Tarih talep edildi",
    "All":"Hepsi",
    "Approved":"Onaylanan İzin Talepleri",
    "Pending":"Beklemede Olan İzin Talepleri",
    "Google Events":"Google Etkinlikleri",
    "Birthdays":"Doğum Günleri",
    "Leave":"İzinler",
    "Common Events":"Ortak Etkinlikler",
    "Sun":"Pazar",
    "Mon":"Ptesi",
    "Tue":"Salı",
    "Wed":"Çar",
    "Thu":"Per",
    "Fri":"Cuma",
    "Sat":"Ctesi",
    "Upcoming Events":"Yaklaşan Etkinlikler",
    "Upcoming Birthdays":"Yaklaşan Doğum Günleri",
    "Upcoming Users' Anniversaries":"Yaklaşan Kullanıcı Yıldönümleri",
    "Birthday":"Doğum günü",
    "Anniversary":"Yıldönümü",
    "Anniversary Detail":"Yıldönümü Detayı",
    "day left":"gün kaldı",
    "Update Info":"Bilgileri Güncelle",
    "General":"Genel",
    "Surname":"Soyadı",
    "Phone":"Telefon",
    "Day":"Gün",
    "Working type":"Çalışma türü",
    "User Types":"Kullanıcı türleri",
    "Id number":"Kimlik numarası ",
    "Marital Status":"Medeni durum",
    "Married":"Evli",
    "Single":"Bekar",
    "Gender":"Cinsiyet",
    "Male":"Erkek",
    "Female":"Kadın",
    "Education Status":"Eğitim durumu",
    "High School":"Lise",
    "University":"Üniversite",
    "Disability":"Engellilik durumu",
    "Nationality":"Milliyet",
    "Blood type":"Kan grubu",
    "Number of Children":"Çocuk sayısı",
    "Address Info":"Adres bilgisi",
    "Address Full":"Tam adres",
    "Home Phone":"Ev telefonu",
    "Country":"Ülke",
    "City":"Şehir",
    "Postal Code":"Posta kodu",
    "Bank":"Banka",
    "Account Owner":"Hesap sahibi",
    "Account number":"Hesap numarası",
    "Personal Info":"Kişisel Bilgiler",
    "Other Info":"Diğer Bilgiler",
    "Bank Info":"Banka Bilgileri",
    "Request a Leave":"İzin Talebi",
    "Previous Requests":"Önceki İzin Talepleri",
    "Message":"Mesaj",
    "Reason of the leave":"İzin sebebi",
    "Your note":"Notunuz",
    "Add Report":"Rapor Ekle",
    "Submit":"Gönder",
    "Days used":"Gün kullanıldı",
    "Employee":"Çalışan",
    "Start working day":"İşe Başlama Günü",
    "Total Working Days":"Toplam İş Günü",
    "Days left out of":"Gün kaldı",
    "days":"gün içerisinden",
    "Photo":"Fotoğraf",
    "Sick":"Hasta",
    "Vacation":"Tatil",
    "Leave reason":"İzin sebebi",
    "Coming Age":"Yaklaşan Yaş",
    "WARNING":"UYARI",
    "You must have been working for at least 3 months to request a leave":"İzin talebinde bulunmak için en az 3 aydır çalışıyor olmanız gerekir",
    "You have to wait until":"kadar beklemeniz gerekiyor",
    "Download Leaves’ Report":"İzin Raporu İndir",
    "Select user":"Kullanıcı Seç",
    "Download Leave Requests for the last":"Son İzin İsteklerini İndir",
    "1 month’s report":"1 aylık rapor",
    "3 month’s report":"3 aylık rapor",
    "6 month’s report":"6 aylık rapor",
    "1 year’s report":"1 yıllık rapor",
    "Download":"İndir",
    "Download Excel":"Excel İndir",
    "Prepare Excel":"Excel Dosyası Hazırla",
    "Passed":"Geçti",
  }
  