import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  
        is_admin:null,
        is_manager:null,
        is_employee:null,
        token:""

}

export const userTypeSlices = createSlice({
  name: 'CurrentUserType',
  initialState,
  reducers: {
    setUserTypesandToken: (state,action) => {

      state.token = action.payload.token
        state.is_admin = action.payload.is_admin
        state.is_manager = action.payload.is_manager
        state.is_employee = action.payload.is_employee

    },

  },
})

// Action creators are generated for each case reducer function
export const { setUserTypesandToken } = userTypeSlices.actions

export default userTypeSlices